import { DOCUMENT } from '@angular/common';
import {
	Directive,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';

import {
	filter,
	fromEvent,
	Observer,
	skipUntil,
	Subscription,
	timer,
} from 'rxjs';

@Directive({
	selector: '[valkCoreClickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
	@Input() excludeFromClosingRegex: RegExp | string | undefined;
	@Output() clickOutside = new EventEmitter<MouseEvent>();

	private subscription!: Subscription;

	constructor(
		private readonly elementRef: ElementRef,
		@Inject(DOCUMENT) private readonly _document: Document,
	) {}

	ngOnInit(): void {
		this.subscription = fromEvent(this._document, 'click')
			.pipe(
				// Delay as the click to open the dialog might not have been fully captured.
				skipUntil(timer(0)),
				filter((event: Event) => {
					if (this.excludeFromClosingRegex) {
						return !!(
							!this.elementRef.nativeElement.contains(
								event.target,
							) &&
							!(event.target as HTMLElement)['className']
								.toString()
								.match(this.excludeFromClosingRegex)
						);
					}
					return !this.elementRef.nativeElement.contains(
						event.target,
					);
				}),
			)
			.subscribe(
				this.clickOutside as unknown as Partial<Observer<Event>>,
			);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
