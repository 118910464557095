import { NgClass } from '@angular/common';
import {
	Component,
	computed,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	input,
} from '@angular/core';

import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-running-head',
	templateUrl: './running-head.html',
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [NgClass, StoryblokDirective],
})
export class RunningHeadComponent extends StoryblokRootDirective {
	@Input({ required: true }) title = '';
	@Input({ required: true }) headingType:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6' = 'h3';
	@Input({ required: true }) alignment:
		| 'running-head-left'
		| 'running-head-right'
		| '' = '';
	size = input.required<'xs' | 'sm' | 'md' | 'lg' | 'xl'>();

	headingSize = computed<string>(() => {
		switch (this.size()) {
			case 'xs':
				return `running-head-xs ${this.alignment}`;
			case 'sm':
				return `running-head-sm ${this.alignment}`;
			case 'lg':
				return `running-head-lg ${this.alignment}`;
			case 'xl':
				return `running-head-xl ${this.alignment}`;
			case 'md':
			default:
				return `running-head ${this.alignment}`;
		}
	});
}
