import { AsyncPipe, CommonModule, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import {
	BehaviorSubject,
	debounceTime,
	filter,
	Observable,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs';

import {
	AvailabilityDatesInterface,
	AvailabilityRequestInterface,
} from '@valk-nx/availability-dates-store/availability-dates.interface';
import { AvailabilityDatesService } from '@valk-nx/availability-dates-store/availability-dates.service';
import { OccupancyInterface } from '@valk-nx/helpers/lib/interfaces/occupancy.interface';
import { toDate } from '@valk-nx/helpers/lib/transformers/toDate';
import { OccupancyRestrictionsInterface } from '@valk-nx/hotel-store/hotels.interface';
import { RouteHelper } from '@valk-nx/router-store/router.helper';

import { AvailabilityWidgetInputDateRangeComponent } from './availability-input-date-range/availability-input-date-range';
import { AvailabilityWidgetInputOccupancyComponent } from './availability-input-occupancy/availability-input-occupancy';

export interface WidgetData {
	numRooms: number;
	numNights: number;
	selectedArrivalDate: string;
	selectedDepartureDate: string;
	price: number;
	currency: string;
	rooms: OccupancyInterface[];
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-availability-widget`,
	templateUrl: './availability-widget.component.html',
	standalone: true,
	imports: [
		AvailabilityWidgetInputDateRangeComponent,
		AvailabilityWidgetInputOccupancyComponent,
		AsyncPipe,
		CommonModule,
		TranslateModule,
	],
})
export class AvailabilityWidgetComponent implements OnInit {
	@Input() roomGUID: string | undefined;
	@Input() dealGUID: string | undefined;
	@Input() packageGUID: string | undefined;
	@Input() accommodationTypes: string[] | undefined;
	@Input({ required: true }) hotelGUID: string;
	@Input() defaultAdults = 2;
	@Input() occupancyRestrictions: OccupancyRestrictionsInterface = {
		adults: {
			min: 1,
			max: 3,
		},
		children: {
			min: 0,
			max: 1,
		},
		infants: {
			min: 0,
			max: 1,
		},
		maxPersons: 2,
		maxOccupancies: 5,
	};
	@Input() maxDate = dayjs().add(1, 'year').toDate();
	@Input() minDate = dayjs().subtract(1, 'day').toDate();
	@Input({ required: true }) bookingtoolUrl: string;
	@Input({ transform: toDate }) initialArrivalDate: Date;
	@Input() initialOccupancy: OccupancyInterface[];

	occupancy: OccupancyInterface[];

	updateAvailability$ = new BehaviorSubject<AvailabilityRequestInterface>(
		undefined,
	);
	isLoading = false;
	jumpToFirstAvailableDate = true;

	availability$: Observable<AvailabilityDatesInterface>;
	firstDateInCalendar: string = dayjs().format('YYYY-MM-DD');
	isOccupancyOpen = false;
	isDatePickerOpen = false;

	arrivalDate: Date;
	departureDate: Date;

	constructor(
		private readonly availability: AvailabilityDatesService,
		private readonly translate: TranslateService,
		@Inject(PLATFORM_ID) readonly platformId: string,
	) {
		this.availability$ = this.updateAvailability$.pipe(
			debounceTime(300),
			filter((request) => !!request),
			switchMap((request) =>
				this.availability.getAvailabilityDates(
					request.hotelGUID,
					request.endDate,
					request.startDate,
					request.occupancies,
					request.accommodationTypes,
					request.dealGUID,
					request.packageGUID,
					request.roomGUID,
				),
			),
			tap(() => (this.isLoading = false)),
			shareReplay(1),
		);
	}

	ngOnInit() {
		if (this.initialArrivalDate) {
			this.firstDateInCalendar = dayjs(this.initialArrivalDate)
				.startOf('month')
				.format('YYYY-MM-DD');
		}
		this.getAvailability();
	}

	onChooseDatesEmit(event: Event) {
		this.isOccupancyOpen = false;
		this.isDatePickerOpen = true;

		this.getAvailability();

		event.stopPropagation();
	}

	onDatePickerOpened(isOpen: boolean) {
		this.isDatePickerOpen = isOpen;
	}

	onOccupancyOpenEmit(isOpen: boolean) {
		this.isOccupancyOpen = isOpen;
	}

	onOccupancyEmit(occupancy: OccupancyInterface[]) {
		this.occupancy = occupancy;
		this.getAvailability();
	}

	recalculateAvailabilityForToday() {
		this.firstDateInCalendar = dayjs()
			.startOf('month')
			.format('YYYY-MM-DD');
		this.getAvailability();
	}

	onMonthChange(newFirstDate: string) {
		this.firstDateInCalendar = newFirstDate;

		this.jumpToFirstAvailableDate = false;
		this.getAvailability();
	}

	getAvailability() {
		if (isPlatformBrowser(this.platformId)) {
			const request: AvailabilityRequestInterface = {
				accommodationTypes: this.accommodationTypes,
				hotelGUID: this.hotelGUID,
				startDate: this.firstDateInCalendar,
				endDate: dayjs(this.firstDateInCalendar)
					.add(2, 'month')
					.format('YYYY-MM-DD'),
				occupancies: this.occupancy,
				dealGUID: this.dealGUID,
				packageGUID: this.packageGUID,
				roomGUID: this.roomGUID,
			};

			this.isLoading = true;
			this.updateAvailability$.next(request);
		}
	}

	updateModel(event) {
		this.arrivalDate = event.arrivalDate;
		this.departureDate = event.departureDate;
	}

	goToBookingtool() {
		if (this.bookingtoolUrl) {
			const arrivalString = this.arrivalDate
				? `&startDate=${dayjs(this.arrivalDate).format('YYYY-MM-DD')}`
				: '';
			const departureString = this.departureDate
				? `&endDate=${dayjs(this.departureDate).format('YYYY-MM-DD')}`
				: '';
			const occupancyString = this.occupancy
				? `&occupancy=${JSON.stringify(this.occupancy)}`
				: '';
			const roomString = this.roomGUID
				? `&roomGUID=${this.roomGUID}`
				: '';
			const dealString = this.dealGUID
				? `&dealGUID=${this.dealGUID}`
				: '';
			const packageString = this.packageGUID
				? `&packageGUID=${this.packageGUID}`
				: '';
			const url = `${this.bookingtoolUrl}/?${arrivalString}${departureString}${occupancyString}${roomString}${dealString}${packageString}`;

			RouteHelper.redirectToExternalUrl(url);
		}
	}
}
