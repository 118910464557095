@if (package) {
	<div data-test-id="package-detail">
		<section class="bg-white px-3 py-8 md:px-8 md:py-12 lg:py-16 2xl:px-10">
			<div
				class="mx-auto flex max-w-screen-2xl flex-col gap-y-4 md:gap-y-6 2xl:gap-y-8"
			>
				<div
					class="flex w-full flex-col gap-4 md:mx-auto md:flex-row md:gap-6 lg:w-10/12 2xl:gap-8"
				>
					<div class="flex w-full flex-col gap-8 lg:w-1/2 xl:w-2/3">
						<div>
							<header>
								<h1
									class="heading-group heading-group-lg"
									data-test-id="room-detail-title"
								>
									{{ package.name }}
								</h1>
							</header>
						</div>
						<vp-read-more>
							<markdown
								[data]="package.description"
								data-test-id="room-detail-description"
							/>
						</vp-read-more>
					</div>
				</div>
			</div>
		</section>
	</div>
}
