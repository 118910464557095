<vp-modal
	[closeButton]="false"
	[closeOnEscape]="false"
	[closeOnOutsideClick]="false"
	[isMaxWScreenLG]="true"
	[showFooter]="true"
	[showingModal]="showModal"
	[title]="mergedTranslations.title"
	(emitClose)="modalClosed()"
	class="font-sans-serif"
>
	<div class="flex flex-col gap-4 md:flex-row md:gap-12 md:text-lg">
		<div class="md:mr-12">
			{{ mergedTranslations.content }}
			{{ mergedTranslations.extra.content }}
			<a [href]="mergedTranslations.extra.url" class="text-link">
				{{ mergedTranslations.extra.link }}
			</a>
		</div>
		<div
			class="gap-4 self-start sm:flex sm:flex-1 sm:flex-row md:justify-end md:gap-6 md:self-center"
		>
			<button
				(click)="accept()"
				class="btn btn-primary mr-4 inline-block md:mr-0"
				data-test-id="cookie-accept-button"
			>
				{{ mergedTranslations.buttons.contentText }}
			</button>
			<button
				(click)="toggleMoreInfo()"
				class="text-link my-4 inline-block min-w-fit text-left sm:text-center md:my-0"
				data-test-id="cookie-more-info-link"
			>
				{{ mergedTranslations.links.contentText }}
			</button>
		</div>
	</div>
	@if (showMoreInfo) {
		<div class="!ml-1 !mt-8 sm:!mt-12">
			<div class="flex flex-col sm:grid sm:grid-cols-9">
				<ng-container
					[ngTemplateOutlet]="cookie"
					[ngTemplateOutletContext]="{
						cookie: 'functional'
					}"
				/>
			</div>
			<div class="mt-6 flex flex-col sm:grid sm:grid-cols-9">
				<ng-container
					[ngTemplateOutlet]="cookie"
					[ngTemplateOutletContext]="{
						cookie: 'analytics'
					}"
				/>
			</div>
			<div class="mt-6 flex flex-col sm:grid sm:grid-cols-9">
				<ng-container
					[ngTemplateOutlet]="cookie"
					[ngTemplateOutletContext]="{
						cookie: 'marketing'
					}"
				/>
			</div>
		</div>
	}
	@if (showMoreInfo) {
		<div
			class="flex w-full flex-col justify-between gap-4 md:flex-row md:gap-6"
			footer
		>
			<button
				(click)="acceptAll()"
				class="btn btn-primary w-full md:w-fit"
				data-test-id="cookie-accept-all-button"
			>
				{{ mergedTranslations.buttons.footerText }}
			</button>
			<button
				(click)="save()"
				class="text-link block md:text-lg"
				data-test-id="cookie-save-link"
			>
				{{ mergedTranslations.links.footerText }}
			</button>
		</div>
	}
</vp-modal>

<ng-template #cookie let-cookie="cookie">
	<vp-checkbox
		[disabled]="cookie !== 'marketing'"
		[initialValue]="cookie === 'marketing' ? useMarketingCookie : true"
		[inputId]="cookie"
		[name]="cookie"
		[showAsToggle]="true"
		(emittedValue)="setMarketingValue($event)"
		class="order-3 !mt-1.5 sm:order-1 sm:col-span-2 sm:!mt-0.5 lg:text-lg"
	>
		{{
			cookie !== 'marketing' || useMarketingCookie
				? mergedTranslations.active
				: mergedTranslations.inactive
		}}
	</vp-checkbox>
	<div class="order-2 sm:col-span-7 md:text-lg">
		<div class="flex justify-between">
			<span class="text-lg font-semibold md:text-xl">
				{{ mergedTranslations.cookies[cookie].header }}
			</span>
			<button
				(click)="toggleCookieDetails(cookie)"
				class="text-link text-base"
			>
				{{
					cookieDetails[cookie]
						? mergedTranslations.hideDetails
						: mergedTranslations.showDetails
				}}
			</button>
		</div>
		@if (cookieDetails[cookie]) {
			<div class="mb-4 md:mb-0">
				{{ mergedTranslations.cookies[cookie].content }}
			</div>
		}
	</div>
</ng-template>
