<div
	[class.bg-accent-10]="useBackground"
	class="items-center py-4 lg:p-10"
	data-test-class="storytelling"
>
	<div class="relative mx-auto max-w-screen-2xl lg:flex">
		<vp-image
			[image]="{
				src: image.src,
				altText: image.alt,
				width: 1208,
				height: 680,
				style: styleHeight,
			}"
			[ngClass]="{
				'lg:ml-1/10 xl:ml-3/20': position === 'right',
				'lg:mr-1/10': position === 'left',
			}"
			[sources]="[
				{ min: null, max: sizes.sm, width: 768, height: 768 },
				{ min: sizes.sm, max: sizes.lg, width: 1024, height: 576 },
				{ min: sizes.lg, max: null, width: 1310, height: 680 },
			]"
			className="object-cover"
		/>
		<div
			#cardContent
			[ngClass]="{
				'sm:ml-1/4 lg:ml-11/20': position === 'left',
				'sm:mr-1/4 lg:mr-0': position === 'right',
			}"
			class="card card-lg relative z-10 mx-3 -mt-16 block lg:card-xl sm:mx-8 lg:absolute lg:mx-3 lg:mt-0 lg:w-4/10 lg:self-center"
		>
			<ng-content />
		</div>
	</div>
</div>
