import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import dayjs from 'dayjs';
import { MarkdownModule } from 'ngx-markdown';
import { Observable } from 'rxjs';

import { RibbonColor } from '@valk-nx/components/ui-ribbon/src/lib/ribbon';
import { HotelByDealCardInterface } from '@valk-nx/compositions/ui-hotel-by-deal-card-list/src/lib/hotel-by-deal-card-interface';
import { HotelByDealCardListModule } from '@valk-nx/compositions/ui-hotel-by-deal-card-list/src/lib/hotel-by-deal-card-list.module';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import { StoryblokParamsService } from '@valk-nx/storyblok-services/src/lib/services/storyblok-params.service';
import { HotelByDealCardInterface as SBHotelByDealCardInterface } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-hotel-by-deal-list',
	templateUrl: './hotel-by-deal-list.html',
	standalone: true,
	imports: [
		CommonModule,
		HotelByDealCardListModule,
		MarkdownModule,
		StoryblokDirective,
	],
})
export class HotelByDealListComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() hotels: SBHotelByDealCardInterface[] = [];

	hotelList: HotelByDealCardInterface[] = [];
	highlightedHotelSlug$!: Observable<string>;

	constructor(
		private readonly storyblokParamsService: StoryblokParamsService,
	) {
		super();
	}

	ngOnInit() {
		this.hotelList = this.hotels
			.filter((hotel) => hotel.component === 'hotelByDealCard')
			.map((sbHotelByDeal) => {
				let image: PhotoInterface;
				if (sbHotelByDeal.image[0]?.bynder?.length > 0) {
					image = StoryblokHelper.mapBynderToPhotoInterface(
						sbHotelByDeal.image[0].bynder[0],
						sbHotelByDeal.image[0].imageAlt,
					);
				} else {
					image = {
						src: '',
						alt: '',
					};
				}

				return {
					...sbHotelByDeal,
					usps: sbHotelByDeal.usps.map((usp) => usp.text),
					tags: sbHotelByDeal.tags.map((tag) => tag.text),
					image,
					price: +sbHotelByDeal.price,
					numberOfStars: +sbHotelByDeal.numberOfStars,
					rating: +sbHotelByDeal.rating,
					numberOfRatings: parseInt(
						sbHotelByDeal.numberOfRatings,
						10,
					),
					ribbonColor: sbHotelByDeal.ribbonColor as RibbonColor,
					ribbonText: sbHotelByDeal.ribbonText,
					ctaUrl: sbHotelByDeal.ctaUrl.cached_url,
					validUntil: dayjs(sbHotelByDeal.validUntil).toDate(),
					validFrom: dayjs(sbHotelByDeal.validFrom).toDate(),
					currency: sbHotelByDeal.currency ?? 'EUR',
				};
			});

		this.highlightedHotelSlug$ =
			this.storyblokParamsService.getFromUrlParam('hotel');
	}
}
