<div [editableInStoryblok]="_editable">
	@switch (headingType) {
		@case ('h1') {
			<h1 [ngClass]="headingSize()">{{ title }}</h1>
		}
		@case ('h2') {
			<h2 [ngClass]="headingSize()">{{ title }}</h2>
		}
		@case ('h3') {
			<h3 [ngClass]="headingSize()">{{ title }}</h3>
		}
		@case ('h4') {
			<h4 [ngClass]="headingSize()">{{ title }}</h4>
		}
		@case ('h5') {
			<h5 [ngClass]="headingSize()">{{ title }}</h5>
		}
		@case ('h6') {
			<h6 [ngClass]="headingSize()">{{ title }}</h6>
		}
	}
</div>
