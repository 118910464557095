import { NgClass } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';

import { HeadingGroupComponent } from '@valk-nx/storyblok/components/heading-group/src/lib/heading-group';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-heading-group-section',
	templateUrl: './heading-group-section.html',
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		AngularSvgIconModule,
		HeadingGroupComponent,
		NgClass,
		MarkdownModule,
		StoryblokDirective,
	],
})
export class HeadingGroupSectionComponent extends StoryblokRootDirective {
	@Input() topFigure = '';
	@Input() bottomFigure = '';
	@Input() overline = '';
	@Input({ required: true }) title = '';
	@Input() content = '';
	@Input({ required: true }) headingType:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6' = 'h3';
}
