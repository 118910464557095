import { AsyncPipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable, switchMap } from 'rxjs';

import { AvailabilityWidgetComponent } from '@valk-nx/compositions/ui-availability-widget/src/lib/availability-widget.component';
import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import { ContentHotelInterface } from '@valk-nx/hotel-store/hotels.interface';
import { HotelsService } from '@valk-nx/hotel-store/hotels.service';
import { CarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { HOTEL_SLUG } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { Carousel } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-carousel-with-availability-widget',
	templateUrl: './carousel-with-availability-widget.html',
	standalone: true,
	imports: [
		AsyncPipe,
		CarouselComponent,
		AvailabilityWidgetComponent,
		StoryblokDirective,
	],
})
export class CarouselWithAvailabilityWidgetComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() blocks: Carousel[] = [];
	@Input({ required: true }) hotelGUID!: string;

	carousel!: Carousel;
	hotel$: Observable<ContentHotelInterface>;
	translatedSlugs$: Observable<TranslatedSlug[]>;

	constructor(
		private readonly sbService: StoryblokService,
		private readonly hotelService: HotelsService,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();
		this.translatedSlugs$ =
			this.sbService.translatedSlugs$.pipe(takeUntilDestroyed());
	}

	ngOnInit() {
		this.hotel$ = this.translatedSlugs$.pipe(
			switchMap((translatedSlug) => {
				const slug =
					translatedSlug.find((x) => x.selected) || translatedSlug[0];
				return this.hotelService.getHotelBySlugData(
					slug.lang.toLowerCase(),
					this.hotelSlug,
				);
			}),
		);

		this.carousel = this.blocks.find((x) => x.component === 'carousel');
	}
}
