/* istanbul ignore file */

import { FormControl, FormGroup } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';

export type FormArguments = {
	formName: string;
	maxAmountCards: number;
	minOrderValueCards: number;
	maxOrderValueCards: number;
	minOrderValueCardsCurrency: string | null;
	maxOrderValueCardsCurrency: string | null;
	isEGiftcard: boolean;
};

export const orderFormGroup = new FormGroup({
	amountCards: new FormControl(),
	orderValueCards: new FormControl(),
	customer: new FormGroup({
		salutation: new FormControl(),
		firstName: new FormControl(),
		insertion: new FormControl(),
		lastName: new FormControl(),
		countryCode: new FormControl(),
		postalCode: new FormControl(),
		houseNumber: new FormControl(),
		houseNumberAddition: new FormControl(),
		street: new FormControl(),
		city: new FormControl(),
		emailAddress: new FormControl(),
		phoneNumber: new FormControl(),
	}),

	differentDeliveryAddress: new FormControl(),
	recipient: new FormGroup({
		salutation: new FormControl(),
		firstName: new FormControl(),
		insertion: new FormControl(),
		lastName: new FormControl(),
		countryCode: new FormControl(),
		postalCode: new FormControl(),
		houseNumber: new FormControl(),
		houseNumberAddition: new FormControl(),
		street: new FormControl(),
		city: new FormControl(),
		emailAddress: new FormControl(),
	}),
	message: new FormControl(),
});

export const form = (args: FormArguments): FormlyFieldConfig[] => {
	return [
		{
			props: {
				label: args.formName,
			},
			type: 'heading',
		},
		{
			fieldGroupClassName:
				'grid grid-cols-2 gap-x-4 lg:gap-x-6 2xl:gap-x-8',
			fieldGroup: [
				{
					id: 'amountCards',
					key: 'amountCards',
					props: {
						max: args.maxAmountCards,
						min: 1,
						label: 'storyblok.giftcard.number-of-cards',
						labelExtras: {
							helperText:
								'storyblok.giftcard.maximum-number-of-cards',
							helperTextParams: { amount: args.maxAmountCards },
							noPadding: true,
						},
						required: true,
						translate: true,
					},
					validation: {
						messages: {
							max: 'storyblok.giftcard.order-max-card',
							min: 'storyblok.giftcard.order-min-card',
						},
						params: {
							max: args.maxAmountCards,
							min: 1,
						},
					},
					type: 'number',
				},
				{
					id: 'orderValueCards',
					key: 'orderValueCards',
					props: {
						max: args.maxOrderValueCards,
						min: args.minOrderValueCards,
						label: 'storyblok.giftcard.order-amount',
						labelExtras: {
							helperText: 'storyblok.giftcard.order-amount-limit',
							helperTextParams: {
								min: args.minOrderValueCards,
								max: args.maxOrderValueCards,
							},
							noPadding: true,
						},
						maskOptions: {
							mask: '',
							prefix: '€',
							showMaskedType: true,
						},
						required: true,
						translate: true,
					},
					validation: {
						messages: {
							max: 'storyblok.giftcard.order-max-amount',
							min: 'storyblok.giftcard.order-min-amount',
						},
						params: {
							max: args.maxOrderValueCardsCurrency,
							min: args.minOrderValueCardsCurrency,
						},
					},
					type: 'number',
				},
			],
		},
		{
			className: 'section-label',
			type: 'divider',
		},
		{
			props: {
				label: 'storyblok.giftcard.recipient-data',
			},
			type: 'heading',
		},
		{
			id: 'customer-salutation',
			key: 'customer.salutation',
			props: {
				label: 'global.salutation.label',
				segmentedOptions: [
					{ name: 'global.madam', value: 'MRS' },
					{ name: 'global.mister', value: 'MR' },
				],
				required: true,
				translate: true,
				validation: {
					messages: {
						required: 'global.salutation.required-text',
					},
				},
			},
			type: 'segmentedControl',
		},
		{
			id: 'customer-firstName',
			key: 'customer.firstName',
			props: {
				label: 'global.firstname.label',
				required: true,
				translate: true,
				validation: {
					messages: {
						required: 'global.firstname.required-text',
					},
				},
			},
			type: 'text',
		},
		{
			fieldGroupClassName:
				'grid grid-cols-2 gap-x-4 lg:gap-x-6 2xl:gap-x-8',
			fieldGroup: [
				{
					id: 'customer-insertion',
					key: 'customer.insertion',
					props: {
						label: 'global.insertion.label',
						labelExtras: {
							requiredHelperText: 'global.optional',
						},
						required: false,
						translate: true,
						optional: true,
					},
					type: 'text',
				},
			],
		},
		{
			id: 'customer-lastName',
			key: 'customer.lastName',
			props: {
				label: 'global.lastname.label',
				required: true,
				translate: true,
				validation: {
					messages: {
						required: 'global.lastname.required-text',
					},
				},
			},
			type: 'text',
		},
		{
			expressions: {
				hide: () => {
					return args.isEGiftcard;
				},
			},
			fieldGroup: [
				{
					className: 'section-label',
					type: 'divider',
				},
				{
					id: 'customer-countryCode',
					key: 'customer.countryCode',
					defaultValue: 'NL',
					props: {
						useAutoComplete: false,
						label: 'global.country.label',
						required: true,
						searchable: true,
						translate: true,
						selectType: 'giftcardCountries',
						selectOptions: [
							{ value: 'NL' },
							{ value: 'BE' },
							{ value: 'DE' },
							{ value: 'FR' },
							{ value: 'ES' },
						],
						validation: {
							messages: {
								required: 'global.country.required-text',
							},
						},
						variant: 'integrated',
					},
					type: 'select',
				},
				{
					fieldGroupClassName:
						'grid grid-cols-2 gap-x-4 lg:gap-x-6 2xl:gap-x-8',
					fieldGroup: [
						{
							id: 'customer-postalCode',
							key: 'customer.postalCode',
							props: {
								label: 'global.postalcode.label',
								required: true,
								translate: true,
								validation: {
									messages: {
										required:
											'global.postalcode.required-text',
									},
								},
							},
							type: 'text',
						},
					],
				},
				{
					fieldGroupClassName:
						'grid grid-cols-2 gap-x-4 lg:gap-x-6 2xl:gap-x-8',
					fieldGroup: [
						{
							id: 'customer-houseNumber',
							key: 'customer.houseNumber',
							props: {
								label: 'global.housenumber.label',
								required: true,
								translate: true,
								validation: {
									messages: {
										required:
											'global.housenumber.required-text',
									},
								},
							},
							type: 'number',
						},
						{
							id: 'customer-houseNumberAddition',
							key: 'customer.houseNumberAddition',
							props: {
								label: 'global.housenumber-addition.label',
								labelExtras: {
									requiredHelperText: 'global.optional',
								},
								required: false,
								translate: true,
								optional: true,
							},
							type: 'text',
						},
					],
				},
				{
					id: 'customer-loadingAddress',
					key: 'customer.loadingAddress',
					props: {
						type: 'hidden',
						required: false,
						translate: false,
					},
				},
				{
					id: 'customer-street',
					key: 'customer.street',
					props: {
						label: 'global.street.label',
						required: true,
						translate: true,
						validation: {
							messages: {
								required: 'global.street.required-text',
							},
						},
					},
					expressions: {
						'props.isLoading': 'model.loadingAddress',
					},
					type: 'text',
				},
				{
					id: 'customer-city',
					key: 'customer.city',
					props: {
						label: 'global.city.label',
						required: true,
						translate: true,
						validation: {
							messages: {
								required: 'global.city.required-text',
							},
						},
					},
					expressions: {
						'props.isLoading': 'model.loadingAddress',
					},
					type: 'text',
				},
			],
		},
		{
			className: 'section-label',
			type: 'divider',
		},
		{
			id: 'customer-emailAddress',
			key: 'customer.emailAddress',
			props: {
				label: 'global.email.label',
				required: true,
				translate: true,
				validation: {
					messages: {
						required: 'global.email.required-text',
					},
				},
			},
			type: 'email',
		},
		{
			id: 'customer-phoneNumber',
			key: 'customer.phoneNumber',
			props: {
				label: 'global.phonenumber.label',
				required: true,
				translate: true,
				validation: {
					messages: {
						required: 'global.phonenumber.required-text',
					},
				},
			},
			type: 'tel',
			wrappers: ['label'],
		},
		{
			id: 'differentDeliveryAddress',
			key: 'differentDeliveryAddress',
			defaultValue: false,
			props: {
				checkboxLabel: args.isEGiftcard
					? 'storyblok.giftcard.e-card-different-email-address'
					: 'storyblok.giftcard.different-delivery-address',
				translate: true,
				optional: true,
			},
			type: 'checkbox',
			wrappers: ['labelspan'],
		},
		{
			expressions: {
				hide: '!model.differentDeliveryAddress',
			},
			fieldGroup: [
				{
					className: 'section-label',
					type: 'divider',
				},
				{
					props: {
						label: 'storyblok.giftcard.different-recipient-label',
					},
					type: 'heading',
				},
				{
					id: 'recipient-salutation',
					key: 'recipient.salutation',
					props: {
						label: 'global.salutation.label',
						segmentedOptions: [
							{ name: 'global.madam', value: 'MRS' },
							{ name: 'global.mister', value: 'MR' },
						],
						required: true,
						translate: true,
						validation: {
							messages: {
								required: 'global.salutation.required-text',
							},
						},
					},
					type: 'segmentedControl',
				},
				{
					id: 'recipient-firstName',
					key: 'recipient.firstName',
					props: {
						label: 'global.firstname.label',
						required: true,
						translate: true,
						validation: {
							messages: {
								required: 'global.firstname.required-text',
							},
						},
					},
					type: 'text',
				},
				{
					id: 'recipient-insertion',
					key: 'recipient.insertion',
					props: {
						label: 'global.insertion.label',
						labelExtras: {
							contentWidth: 'sm:w-1/2',
							requiredHelperText: 'global.optional',
							width: 'sm:w-1/2',
						},
						required: false,
						translate: true,
						optional: true,
					},
					type: 'text',
				},
				{
					id: 'recipient-lastName',
					key: 'recipient.lastName',
					props: {
						label: 'global.lastname.label',
						required: true,
						translate: true,
						validation: {
							messages: {
								required: 'global.lastname.required-text',
							},
						},
					},
					type: 'text',
				},
				{
					expressions: {
						hide: () => {
							return args.isEGiftcard;
						},
					},
					fieldGroup: [
						{
							className: 'section-label',
							type: 'divider',
						},
						{
							id: 'recipient-countryCode',
							key: 'recipient.countryCode',
							defaultValue: 'NL',
							props: {
								useAutoComplete: false,
								label: 'global.country.label',
								required: true,
								selectType: 'giftcardCountries',
								selectOptions: [
									{ value: 'NL' },
									{ value: 'BE' },
									{ value: 'DE' },
									{ value: 'FR' },
									{ value: 'ES' },
								],
								searchable: true,
								translate: true,
								validation: {
									messages: {
										required:
											'global.country.required-text',
									},
								},
								variant: 'integrated',
							},
							type: 'select',
						},
						{
							fieldGroupClassName:
								'grid grid-cols-2 gap-x-4 lg:gap-x-6 2xl:gap-x-8',
							fieldGroup: [
								{
									id: 'recipient-postalCode',
									key: 'recipient.postalCode',
									props: {
										label: 'global.postalcode.label',
										required: true,
										translate: true,
										validation: {
											messages: {
												required:
													'global.postalcode.required-text',
											},
										},
									},
									type: 'text',
								},
							],
						},
						{
							fieldGroupClassName:
								'grid grid-cols-2 gap-x-4 lg:gap-x-6 2xl:gap-x-8',
							fieldGroup: [
								{
									id: 'recipient-houseNumber',
									key: 'recipient.houseNumber',
									props: {
										label: 'global.housenumber.label',
										required: true,
										translate: true,
										validation: {
											messages: {
												required:
													'global.housenumber.required-text',
											},
										},
									},
									type: 'number',
								},
								{
									id: 'recipient-houseNumberAddition',
									key: 'recipient.houseNumberAddition',
									props: {
										label: 'global.housenumber-addition.label',
										labelExtras: {
											requiredHelperText:
												'global.optional',
										},
										required: false,
										translate: true,
										optional: true,
									},
									type: 'text',
								},
							],
						},
						{
							id: 'recipient-loadingAddress',
							key: 'recipient.loadingAddress',
							props: {
								type: 'hidden',
								required: false,
								translate: false,
							},
						},
						{
							id: 'recipient-street',
							key: 'recipient.street',
							props: {
								label: 'global.street.label',
								required: true,
								translate: true,
								validation: {
									messages: {
										required: 'global.street.required-text',
									},
								},
							},
							expressions: {
								'props.isLoading': 'model.loadingAddress',
							},
							type: 'text',
						},
						{
							id: 'recipient-city',
							key: 'recipient.city',
							props: {
								label: 'global.city.label',
								required: true,
								translate: true,
								validation: {
									messages: {
										required: 'global.city.required-text',
									},
								},
							},
							expressions: {
								'props.isLoading': 'model.loadingAddress',
							},
							type: 'text',
						},
					],
				},

				{
					className: 'section-label',
					type: 'divider',
				},
				{
					id: 'recipient-emailAddress',
					key: 'recipient.emailAddress',
					props: {
						label: 'global.email.label',
						required: true,
						translate: true,
						validation: {
							messages: {
								required: 'global.email.required-text',
							},
						},
					},
					type: 'email',
				},
				{
					id: 'message',
					key: 'message',
					props: {
						label: 'storyblok.giftcard.recipient-message',
						labelExtras: {
							requiredHelperText: 'global.optional',
						},
						required: false,
						translate: true,
						maxLength: 350,
						rows: 5,
					},
					type: 'textarea',
				},
			],
		},
	];
};
