<div *ngrxLet="isMobile$ as isMobile" class="card md:card-lg">
	<div
		[ngClass]="{
			'mb-8': !disabled,
		}"
	>
		<h2 class="h3 font-semibold">{{ title }}</h2>

		<markdown [data]="bulletList" class="list-checkmarks" />
	</div>
	@if (!disabled) {
		<div class="flex flex-col-reverse md:flex-row">
			<div class="mt-3 flex items-end md:mt-0">
				<a
					[href]="ctaUrl.href"
					[target]="ctaUrl.target"
					class="btn btn-primary"
					>{{ ctaLabel }}</a
				>
			</div>
			<div class="flex w-full justify-start md:ml-4 md:justify-end">
				<vp-pricing
					[conditions]="
						'global.starting-price-excl-taxes' | translate
					"
					[currency]="currency"
					[indication]="false"
					[isHorizontal]="isMobile"
					[price]="price"
					[size]="isMobile ? 'lg' : 'xl'"
				/>
			</div>
		</div>
	}
</div>
