<div>
	@for (blok of content; track blok) {
		<div>
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		</div>
	}
	<section class="bg-white px-3 py-8 md:px-8 md:py-12 lg:py-16 2xl:px-10">
		<div
			class="mx-auto flex max-w-screen-2xl flex-col gap-y-4 md:gap-y-6 2xl:gap-y-8"
		>
			<div class="flex w-full flex-col gap-8 md:mx-auto lg:w-10/12">
				@for (roomsWithCategory of roomsData; track $index) {
					@if (roomsWithCategory.rooms.length > 0) {
						<h3
							class="running-head mb-0"
							data-test-class="running-head"
						>
							{{ roomsWithCategory.category }}
						</h3>
						@for (room of roomsWithCategory.rooms; track $index) {
							<vp-persuade-card
								[content]="room.summary"
								[ctaUrl]="
									room.isBookable
										? this.bookingtool +
											this.hotelSlug +
											'?roomGUID=' +
											room.GUID
										: null
								"
								[currency]="room.price.currency"
								[facilities]="room.facilities"
								[images]="room.images"
								[price]="
									room.isBookable ? room.price.amount : null
								"
								[readMoreUrl]="currentUrl + '/' + room.slug"
								[roomSurface]="room.surfaceArea"
								[title]="room.title"
							/>
						}
					}
				}
			</div>
		</div>
	</section>
</div>
