import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { Observable } from 'rxjs';

export const NEWSLETTER_SUBSCRIBE_SERVICE_URL = new InjectionToken<string>(
	'newsletter-subscribe-service-url',
);

export const NEWSLETTER_API_COOKIES = new InjectionToken<string[]>(
	'newsletter_api-cookies',
);

@Injectable({ providedIn: 'root' })
export class NewsletterService {
	constructor(
		private readonly http: HttpClient,
		@Optional()
		@Inject(NEWSLETTER_SUBSCRIBE_SERVICE_URL)
		private readonly subscribeUrl: string,
		@Inject(NEWSLETTER_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
	) {}

	newsletterSubscribe(
		emailAddress: string,
	): Observable<{ data: Record<string, never> }> {
		return this.http.post<{ data: Record<string, never> }>(
			this.subscribeUrl,
			{ emailAddress },
			{
				...this.apiCookies,
				observe: 'body',
				responseType: 'json',
			},
		);
	}
}
