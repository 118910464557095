import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AlertModule } from '@valk-nx/components/ui-alert/src/lib/alert.module';
import { ReceiptSummaryComponent } from '@valk-nx/compositions/ui-receipt-summary/src/lib/receipt-summary.component';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';

import { OrderGiftcardProcessingComponent } from './processing/order-giftcard-processing';
import { OrderGiftcardSummaryComponent } from './summary/order-giftcard-summary';
import { OrderGiftcardComponent } from './order-giftcard';

@NgModule({
	declarations: [
		OrderGiftcardComponent,
		OrderGiftcardProcessingComponent,
		OrderGiftcardSummaryComponent,
	],
	exports: [
		OrderGiftcardComponent,
		OrderGiftcardProcessingComponent,
		OrderGiftcardSummaryComponent,
	],
	imports: [
		AlertModule,
		StoryblokDirective,
		CommonModule,
		ReceiptSummaryComponent,
		ReactiveFormsModule,
		SharedFormlyModule,
		TranslateModule.forChild({}),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrderGiftcardModule {}
