import plugin from 'tailwindcss/plugin';
/**
 * This plugin renders different running heads to use in your project
 * It can be extended or overwritten in your tailwind config using the elevations key.
 */

const runningHeads = plugin(
	function ({ addComponents, theme, e }) {
		const sizes = theme('runningHeadSizes');
		const alignments = theme('runningHeadAlignments');
		const sizeEntries = Object.entries(sizes);

		return addComponents([
			sizeEntries.map(([key, value]) => {
				let modifier = '';
				if (key !== 'DEFAULT') {
					modifier = `-${key}`;
				}

				const sizeClass = e(`running-head${modifier}`);
				return {
					[`.${sizeClass}`]: {
						'--vp-running-head-space': value[3],
						'--vp-running-head-flex': '1 1 auto',
						display: 'flex',
						'align-items': 'center',
						color: `var(--running-head-color-text, ${theme(
							'textColor.dark.primary',
						)})`,
						'font-family': theme('fontFamily.sans-serif'),
						'font-size': theme(`fontSize.${value[1]}`),
						'font-weight': theme('fontWeight.normal'),
						'line-height': value[2],
						'letter-spacing': '0.04em',
						'text-transform': 'uppercase',
						'&:before, &:after': {
							content: '""',
							height: '1px',
							'background-color': `var(--running-head-color-divider, ${theme(
								'colors.ui.100',
							)})`,
						},
						'&:before': {
							'margin-right': 'var(--vp-running-head-space)',
							flex: 'var(--vp-running-head-flex, --vp-running-head-space)',
						},
						'&:after': {
							'margin-left': 'var(--vp-running-head-space)',
							flex: '1 1 auto;',
						},
						[`@media (min-width: ${theme('screens.md')})`]: {
							'font-size': theme(`fontSize.${value[0]}`),
						},
					},
				};
			}),

			alignments.map((value) => {
				let beforeValue = '1 1 auto';
				let afterValue = '1 1 auto';

				switch (value) {
					case 'left':
						beforeValue =
							'0 0 calc(var(--vp-running-head-space) * 2)';
						break;
					case 'right':
						afterValue =
							'0 0 calc(var(--vp-running-head-space) * 2)';
						break;
					default:
						break;
				}
				const alignmentClass = e(`running-head-${value}`);

				return {
					[`.${alignmentClass}`]: {
						'&:before': {
							flex: beforeValue,
						},
						'&:after': {
							flex: afterValue,
						},
					},
				};
			}),
		]);
	},
	{
		theme: {
			runningHeadSizes: {
				xs: ['sm', 'xs', '1', '0.5rem'],
				sm: ['base', 'sm', '1', '.75rem'],
				DEFAULT: ['lg', 'base', '1', '1rem'],
				lg: ['xl', 'lg', '1', '1.25rem'],
				xl: ['2xl', 'xl', '1', '1.5rem'],
			},
			runningHeadAlignments: ['center', 'left', 'right'],
		},
	},
);

export default runningHeads;
