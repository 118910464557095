import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { DynamicModule } from 'ng-dynamic-component';
import { combineLatest, from, Observable, take } from 'rxjs';

import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { Language } from '@valk-nx/core/lib/core';
import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	ContentService,
	PackageData,
} from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { ISbStoryLinks } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

type contentStoryblokResponse = [
	ISbStoryLinks,
	HttpResponse<HALResponse<PackageData[]>>,
];

@Component({
	selector: 'sb-package-overview',
	templateUrl: './package-overview.component.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		PersuadeCardComponent,
		StoryblokDirective,
		TranslateModule,
	],
})
export class PackageOverviewComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) content!: unknown[];

	// Needed to "mute" errors in console
	@Input({ required: true }) canonical!: string;
	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) robotsNoFollow!: boolean;
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;

	components: typeof Components;

	slug!: TranslatedSlug;
	packages: PackageData[] = [];

	translatedSlugs$: Observable<TranslatedSlug[]>;
	currentUrl = '';

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private readonly cd: ChangeDetectorRef,
		private router: Router,
	) {
		super();

		// eslint-disable-next-line @nx/enforce-module-boundaries
		import('@valk-nx/storyblok-core/src/lib/components')
			.then((cp) => {
				this.components = cp.Components;
			})
			.catch(
				/* istanbul ignore next */ (error) => {
					console.error(error);
				},
			);

		this.translatedSlugs$ =
			this.sbService.translatedSlugs$.pipe(takeUntilDestroyed());
		this.currentUrl = this.router.url;
	}

	ngOnInit() {
		this.translatedSlugs$.subscribe((translatedSlug) => {
			this.slug = translatedSlug[0];
			const language = (
				translatedSlug.find((x) => x.selected) || translatedSlug[0]
			).lang.toLowerCase() as Language;

			combineLatest([
				from(
					this.sbService.getStoriesByQuery(
						{
							starts_with: this.slug.path,
							excluding_slugs: this.slug.path,
						},
						language,
					),
				),
				this.contentService.getPackages(language),
			])
				.pipe(take(1))
				.subscribe((combinedResponse: contentStoryblokResponse) => {
					const storyblokPackages = combinedResponse[0].data.stories;
					const availablePackages = combinedResponse[1].body.data;

					this.packages = availablePackages.filter(
						(availablePackage) =>
							storyblokPackages.some(
								(storyblokPackage) =>
									storyblokPackage.full_slug
										.split('/')
										.pop() === availablePackage.slug,
							),
					);

					this.cd.markForCheck();
				});
		});
	}
}
