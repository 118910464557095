<vp-storytelling
	[editableInStoryblok]="_editable"
	[image]="vpImage"
	[position]="alignment"
	[useBackground]="useBackground"
>
	<header>
		@switch (headingType) {
			@case ('h1') {
				<h1 class="heading-group heading-group-lg mb-4">
					{{ title }}
				</h1>
				<p class="heading-group-overline" role="doc-subtitle">
					{{ overline }}
				</p>
			}
			@case ('h2') {
				<h2 class="heading-group heading-group-lg mb-4">
					{{ title }}
				</h2>
				<p class="heading-group-overline" role="doc-subtitle">
					{{ overline }}
				</p>
			}
			@case ('h3') {
				<h3 class="heading-group heading-group-lg mb-4">
					{{ title }}
				</h3>
				<p class="heading-group-overline" role="doc-subtitle">
					{{ overline }}
				</p>
			}
			@case ('h4') {
				<h4 class="heading-group heading-group-lg mb-4">
					{{ title }}
				</h4>
				<p class="heading-group-overline" role="doc-subtitle">
					{{ overline }}
				</p>
			}
			@case ('h5') {
				<h5 class="heading-group heading-group-lg mb-4">
					{{ title }}
				</h5>
				<p class="heading-group-overline" role="doc-subtitle">
					{{ overline }}
				</p>
			}
			@case ('h6') {
				<h6 class="heading-group heading-group-lg mb-4">
					{{ title }}
				</h6>
				<p class="heading-group-overline" role="doc-subtitle">
					{{ overline }}
				</p>
			}
		}
	</header>

	<div class="-mt-1.5 flex flex-col gap-1.5">
		@for (blok of content; track blok) {
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		}
	</div>
</vp-storytelling>
