export enum OrderSteps {
	Form = 1,
	Summary = 2,
	Processing = 3,
	Confirmation = 4,
}
export enum OrderStatus {
	success = 'success',
	pending = 'pending',
}

export enum AlertType {
	Success = 'dialog--success',
	Info = 'dialog--info',
}

export type OrderSummary = {
	cards: {
		amount: number;
		taxPercentage: number;
		price: number;
		totalIncl: number;
		totalExcl: number;
	};
	packaging: {
		amount: number;
		taxPercentage: number;
		price: number;
		totalIncl: number;
	};
	administration: {
		amount: number;
		taxPercentage: number;
		price: number;
		totalIncl: number;
	};
	totalIncl: number;
	totalExcl: number;
	taxes: number;
};
