<vp-popover
	*ngrxLet="isSmallTablet$ as isSmallTablet"
	[alignment]="popoverAlignment"
	[boundaryTargetId]="inputName"
	[excludeFromClosingRegex]="excludeFromClosingRegex"
	[overruleCreateInstance]="true"
	[popoverId]="'popover-' + inputName"
	[popoverOffset]="popoverOffset"
	[showHeader]="false"
	[showPopover]="isOpen"
	[triggerId]="'trigger-button-' + inputName"
	(isOpen)="popoverStateChanged($event)"
	className="flex flex-col !p-4 sm:block sm:!p-6"
>
	<div
		#popoverTrigger
		[class]="className"
		[id]="'trigger-button-' + inputName"
		(keydown)="handleKeyDown($event)"
		class="cursor-pointer rounded-input border border-ui-100 bg-ui-10 px-4 py-3.5 focus:border-focus-100 focus-visible:shadow-item-focus focus-visible:shadow-focus-100 focus-visible:outline-none"
		tabindex="0"
		valkCoreClicked
	>
		<div class="flex items-center justify-between">
			<div class="flex flex-col gap-0.5">
				<span class="font-semibold leading-none">{{ label }}</span>
				@if (value) {
					<span class="leading-none">{{ value }}</span>
				} @else {
					<span class="leading-none text-dark-quaternary">
						{{ placeholder }}
					</span>
				}
			</div>
			@if (iconName) {
				<svg-icon
					[src]="'assets/icons/' + iconName + '.svg'"
					class="mr-1 inline-block size-5"
				/>
			}
		</div>
	</div>

	@if (isSmallTablet) {
		<vp-popover-header-title>
			<span class="text-lg font-semibold text-dark-primary">
				{{ header }}
			</span>
		</vp-popover-header-title>
	}

	<vp-popover-content class="flex h-fit flex-col justify-end">
		<ng-content />
	</vp-popover-content>

	<vp-popover-footer>
		<ng-content select="[widget-input-footer]"></ng-content>
	</vp-popover-footer>
</vp-popover>
