<div class="relative w-32">
	<button
		[attr.aria-label]="
			ariaDecreaseLabel || ('components.stepper.decrease' | translate)
		"
		[disabled]="disabled || (min !== undefined && value <= min)"
		[ngClass]="[
			disabled || (min !== undefined && value <= min)
				? 'cursor-not-allowed opacity-50'
				: '',
			!disabled && min && value > min
				? 'focus:border-select-100 focus:shadow-item-focus focus:shadow-focus-100'
				: ''
		]"
		(click)="decrease()"
		class="absolute left-1 top-1 inline-block size-9 flex-none rounded-sm border border-ui-100 bg-white outline-none"
		data-test-id="minus-button"
		type="button"
	>
		<span class="mx-auto block size-3">
			<svg-icon src="assets/icons/icon-minus.svg" />
		</span>
	</button>
	<input
		#stepperInput
		[disabled]="disabled"
		[id]="inputId"
		[max]="max"
		[min]="min"
		[ngClass]="[disabled ? 'cursor-not-allowed opacity-50' : '']"
		[readonly]="readonly"
		[value]="value"
		(change)="onInputChange($event)"
		autocomplete="off"
		class="h-11 w-full appearance-none rounded border border-ui-100 bg-ui-10 text-center outline-none placeholder:font-sans-serif placeholder:text-base placeholder:font-normal hover:border-ui-200 focus:border focus:border-select-100 focus:shadow-item-focus focus:shadow-focus-100"
		pattern="\d*"
		type="number"
	/>
	<button
		[attr.aria-label]="
			ariaIncreaseLabel || ('components.stepper.increase' | translate)
		"
		[disabled]="disabled || (max !== undefined && value >= max)"
		[ngClass]="[
			disabled || (max !== undefined && value >= max)
				? 'cursor-not-allowed opacity-50'
				: '',
			!disabled && max && value < max
				? 'focus:border-select-100 focus:shadow-item-focus focus:shadow-focus-100'
				: ''
		]"
		(click)="increase()"
		class="absolute right-1 top-1 inline-block size-9 flex-none rounded-sm border border-ui-100 bg-white outline-none"
		data-test-id="plus-button"
		type="button"
	>
		<span class="mx-auto block size-3">
			<svg-icon src="assets/icons/icon-plus.svg" />
		</span>
	</button>
</div>
