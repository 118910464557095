import plugin from 'tailwindcss/plugin';

const lists = plugin(function ({ addComponents, theme }) {
	addComponents({
		'.list-checkmarks ul': {
			display: 'flex',
			'flex-direction': 'column',
			gap: '0.5rem',
			'padding-left': '0 !important',
			'list-style-type': 'none !important',

			'& li': {
				'padding-left': '1.5rem',

				'& p': {
					display: 'inline',
				},

				'& > ul': {
					'margin-top': '0.5rem',
				},

				'&::before': {
					display: 'inline-block',
					content: 'var(--tw-content)',
					'margin-left': '-1.5rem',
					'mask-image':
						'url(https://assets.vandervalkonline.com/live/assets/icons/icon-checkmark.svg)',
					'mask-repeat': 'no-repeat',
					'--tw-bg-opacity': '1',
					'background-color': theme('colors.accent.200'),
					width: '1.5rem',
					height: '0.75rem',
				},
			},
		},
		'.list-bullets ul': {
			display: 'flex',
			'flex-direction': 'column',
			gap: '0.5rem',
			'list-style-type': 'disc',
			'list-style-position': 'outside',
			'padding-left': '1.5rem',

			'& p': {
				display: 'inline',
			},

			'& li': {
				'& > ul': {
					'margin-top': '0.5rem',
				},

				'&::marker': {
					color: theme('colors.accent.200'),
				},
			},
		},
	});
	addComponents({
		'.list-grid ul': {
			display: 'grid',
		},
	});
});

export default lists;
