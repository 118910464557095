@if (title) {
	<div
		[ngClass]="{
			'md:w-10/12 lg:w-8/12 2xl:w-6/12':
				component === 'columnOne' && !fullWidth,
			'lg:w-10/12':
				(component === 'columnTwo' || component === 'columnTwoThree') &&
				!fullWidth,
			'2xl:w-8/12':
				(component === 'columnTwo' || component === 'columnThree') &&
				!fullWidth,
		}"
		class="mb-4 flex md:mx-auto md:mb-6 2xl:mb-8"
	>
		@for (titleBlok of title; track titleBlok) {
			@if (components) {
				<div class="w-full">
					<ndc-dynamic
						[ndcDynamicComponent]="
							components[titleBlok['component']]
						"
						[ndcDynamicInputs]="titleBlok"
						class="hidden"
					>
					</ndc-dynamic>
				</div>
			}
		}
	</div>
}
<div
	[editableInStoryblok]="_editable"
	[ngClass]="{
		'md:w-10/12 lg:w-8/12 2xl:w-6/12':
			component === 'columnOne' && !fullWidth,
		'lg:w-10/12':
			(component === 'columnTwo' || component === 'columnTwoThree') &&
			!fullWidth,
		'2xl:w-8/12':
			(component === 'columnTwo' || component === 'columnThree') &&
			!fullWidth,
	}"
	class="flex w-full flex-col gap-4 md:mx-auto md:flex-row md:gap-6 2xl:gap-8"
>
	@if (!colTwo) {
		<div class="w-full">
			<ng-container
				[ngTemplateOutlet]="colInfo"
				[ngTemplateOutletContext]="{ colInfo: colOne }"
			/>
		</div>
	}
	@if (colTwo) {
		<div
			[ngClass]="{
				'md:w-1/3':
					(component === 'columnTwoThree' && !reverse) ||
					(colThree && component !== 'columnTwoThree'),
				'md:w-2/3': component === 'columnTwoThree' && reverse,
				'md:w-1/2': !colThree && component !== 'columnTwoThree',
			}"
			class="w-full"
		>
			<ng-container
				[ngTemplateOutlet]="colInfo"
				[ngTemplateOutletContext]="{ colInfo: colOne }"
			/>
		</div>
	}
	@if (colTwo) {
		<div
			[ngClass]="{
				'md:w-1/3':
					(component === 'columnTwoThree' && reverse) ||
					(colThree && component !== 'columnTwoThree'),
				'md:w-2/3': component === 'columnTwoThree' && !reverse,
				'md:w-1/2': !colThree && component !== 'columnTwoThree',
			}"
			class="w-full"
		>
			<ng-container
				[ngTemplateOutlet]="colInfo"
				[ngTemplateOutletContext]="{ colInfo: colTwo }"
			/>
		</div>
	}
	@if (colThree) {
		<div class="w-full md:w-1/3">
			<ng-container
				[ngTemplateOutlet]="colInfo"
				[ngTemplateOutletContext]="{ colInfo: colThree }"
			/>
		</div>
	}
</div>

<ng-template #colInfo let-colInfo="colInfo">
	<div
		[ngClass]="{
			'h-full': growColumns,
		}"
		class="flex flex-col gap-4 md:gap-6 2xl:gap-8"
	>
		@for (blok of colInfo; track blok) {
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok.component]"
					[ndcDynamicInputs]="blok"
					class="hidden"
				>
				</ndc-dynamic>
			}
		}
	</div>
</ng-template>
