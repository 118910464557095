import { CommonModule, CurrencyPipe, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	PLATFORM_ID,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Placement } from '@popperjs/core';
import dayjs from 'dayjs';
import { Instance as FlatPickrInstance } from 'flatpickr/dist/types/instance';
import { BaseOptions as FlatPickrBaseOptions } from 'flatpickr/dist/types/options';
import {
	combineLatest,
	distinctUntilChanged,
	filter,
	map,
	Observable,
	take,
	tap,
} from 'rxjs';

import {
	CalendarComponent,
	FlatPickrEvent,
	FlatPickrEventType,
} from '@valk-nx/components/ui-calendar/src/lib/calendar';
import { BaseDatePickerComponent } from '@valk-nx/components/ui-date-picker/src/lib/base-date-picker';
import { addOptionalPricing } from '@valk-nx/components/ui-date-picker/src/lib/date-picker.helper';
import { LabelSettings } from '@valk-nx/components/ui-date-picker/src/lib/date-picker.interface';
import { InputComponent } from '@valk-nx/components/ui-input/src/lib/input';
import { InputInterface } from '@valk-nx/components/ui-input/src/lib/input.interface';
import { LabelComponent } from '@valk-nx/components/ui-label/src/lib/label/label';
import { PopoverModule } from '@valk-nx/components/ui-popover/src/lib/popover.module';
import { StepperComponent } from '@valk-nx/components/ui-stepper/src/lib/stepper';
import { ClickedModule } from '@valk-nx/core/lib/directives/clicked/clicked.module';
import { FlatpickrFacade } from '@valk-nx/flatpickr-store/flatpickr.facade';
import { AvailabilityDealsResponse } from '@valk-nx/services/availability-deal/src/lib/availability-deal.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		CalendarComponent,
		StepperComponent,
		ClickedModule,
		CommonModule,
		InputComponent,
		LabelComponent,
		LetDirective,
		PopoverModule,
		TranslateModule,
	],
	providers: [CurrencyPipe, FlatpickrFacade],
	exportAs: 'vpDateRangePickerWithStepper',
	selector: `vp-date-range-picker-with-stepper`,
	templateUrl: './date-range-picker-with-stepper.html',
})
export class DateRangePickerWithStepperComponent
	extends BaseDatePickerComponent
	implements AfterViewInit, OnChanges
{
	@Input({ required: true }) inputConfig: Partial<InputInterface>;
	@Input({ required: true }) inputLabelSettings: LabelSettings;
	@Input() minNights = 1;
	@Input() maxNights: number;
	@Input() initialNumberOfNights: number;
	@Input() initialArrivalDate: Date;
	@Input() jumpToFirstAvailableDate = false;
	@Input() popoverOffset: [number, number] = [0, 8];
	@Input() boundaryTargetId: string;
	@Input() popoverAlignment: Placement = 'bottom-start';
	@Input() override prettyDateFormat = 'dd D MMM';

	@Input({ required: true }) set availability(
		availability: AvailabilityDealsResponse,
	) {
		if (availability) {
			this._availability = availability;
			this.calendarData = Object.keys(availability);
			this.flatpickrFacade.selectStartDate$
				.pipe(
					take(1),
					filter((startDate) => !!startDate),
				)
				.subscribe((startDate) => {
					this.updateDepartureDateAccordingToNightStepper(startDate);
				});
		}
	}

	@Output() stepperUpdated = new EventEmitter<number>();

	config$: Observable<Partial<FlatPickrBaseOptions>>;
	isSmallMobile$: Observable<boolean>;
	isSmallTablet$: Observable<boolean>;

	_availability: AvailabilityDealsResponse = {};
	calendarData: string[];
	locale: string;

	constructor(
		@Inject(PLATFORM_ID) readonly platformId: string,
		private readonly viewport: ViewPortService,
		private readonly translate: TranslateService,
		private readonly currencyPipe: CurrencyPipe,
		override readonly flatpickrFacade: FlatpickrFacade,
	) {
		super(flatpickrFacade);
		this.isSmallMobile$ = this.viewport.isSmallMobile$;
		this.isSmallTablet$ = this.viewport.isSmallTablet$;
		this.config$ = this.adjustConfigToScreenSize();
		this.locale = this.translate.currentLang || this.translate.defaultLang;

		this.prettyDate$ = combineLatest([
			this.flatpickrFacade.selectStartDate$,
			this.flatpickrFacade.selectEndDate$,
		]).pipe(
			map(([startDate, endDate]) => {
				if (startDate && endDate) {
					const arrivalDate = this.formatDate(startDate);
					const departureDate = this.formatDate(endDate);
					return `${arrivalDate} - ${departureDate}`;
				}
				return '';
			}),
		);
	}

	ngAfterViewInit() {
		this.initializeNumberOfNights();

		if (isPlatformBrowser(this.platformId)) {
			this.inputConfig = {
				...this.masterInputConfig,
				...this.inputConfig,
			};
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Make sure the initialValue is only used the first time availability is defined
		if (
			changes['availability']?.previousValue === null &&
			this.initialArrivalDate
		) {
			this.validateInitialDate(this.initialArrivalDate);
		}
	}

	onDatePickerEmitter(event: FlatPickrEvent): void {
		if (event.type === FlatPickrEventType.OnValueUpdate) {
			this.flatpickrFacade.setStartDate(event.selectedDates[0]);
			this.flatpickrFacade.setEndDateAccordingToNights();
			this.updatePrice();
		}
	}

	updateNumberOfNights(numberOfNights: number) {
		this.flatpickrFacade.setNights(numberOfNights);
		this.stepperUpdated.emit(numberOfNights);
	}

	adjustConfigToScreenSize(): Observable<Partial<FlatPickrBaseOptions>> {
		return this.isSmallTablet$.pipe(
			distinctUntilChanged(),
			map((isSmallTablet) => {
				return {
					...this.config,
					showMonths: isSmallTablet ? 1 : this.config.showMonths,
				};
			}),
			tap((config) => {
				this.flatpickrFacade.updateShowMonths(config.showMonths);
			}),
		);
	}

	initializeNumberOfNights() {
		const isInitialNumberOfNightsValid =
			this.initialNumberOfNights &&
			this.initialNumberOfNights >= this.minNights &&
			this.initialNumberOfNights <= this.maxNights;

		const numberOfNights = isInitialNumberOfNightsValid
			? this.initialNumberOfNights
			: this.minNights;

		this.updateNumberOfNights(numberOfNights);
	}

	validateInitialDate(arrivalDate: Date) {
		if (this._availability[this.formatDate(arrivalDate, 'YYYY-MM-DD')]) {
			this.flatpickrFacade.setStartDate(arrivalDate);
			this.flatpickrFacade.jumpToDate(arrivalDate);
			this.updateDepartureDateAccordingToNightStepper(arrivalDate);
		} else {
			this.initialDatesNotAvailable.emit();
		}
	}

	updateDepartureDateAccordingToNightStepper(arrivalDate: Date) {
		if (arrivalDate) {
			const arrivalDateKey = this.formatDate(arrivalDate, 'YYYY-MM-DD');
			if (this.calendarData.includes(arrivalDateKey)) {
				this.flatpickrFacade.setEndDateAccordingToNights();
				this.updatePrice();
			} else {
				this.flatpickrFacade.clearFlatpickrInstance();
			}
		}
	}

	addPricingToDayElement({
		dayElement,
		instance,
	}: {
		dayElement: HTMLElement;
		instance: FlatPickrInstance;
	}) {
		const date =
			this._availability[
				dayjs(dayElement['dateObj']).format('YYYY-MM-DD')
			];
		if (date && this.showPrices) {
			const checkoutDate = Object.keys(date)[0];
			const absolutePrice = date[checkoutDate].price / 100;
			const price = this.currencyPipe.transform(
				absolutePrice,
				this.currency,
				'symbol-narrow',
				'1.0-0',
				this.locale,
			);
			addOptionalPricing(instance, dayElement, price.toString());
		}
	}
}
