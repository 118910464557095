<div class="inline-block">
	<div
		class="rounded-r-sm bg-[#F7D073] p-1.5 text-xs font-semibold uppercase !leading-none tracking-wider text-dark-primary md:p-2 md:text-sm"
	>
		{{ ribbonText }}
	</div>
	<div
		class="size-0 border-b-[5px] border-r-[8px] border-solid border-transparent border-r-[#D7B055]"
	></div>
</div>
