const spacings = {
	'1/2': '50%',
	'1/3': '33.333333%',
	'2/3': '66.666666%',
	'1/4': '25%',
	'2/4': '50%',
	'3/4': '75%',
	'1/6': '16.666667%',
	'2/6': '33.333333%',
	'3/6': '50%',
	'4/6': '66.666667%',
	'5/6': '83.333333%',
	'1/10': '10%',
	'2/10': '20%',
	'3/10': '30%',
	'4/10': '40%',
	'5/10': '50%',
	'6/10': '60%',
	'7/10': '70%',
	'8/10': '80%',
	'9/10': '90%',
	'1/12': '8.333333%',
	'2/12': '16.666667%',
	'3/12': '25%',
	'4/12': '33.333333%',
	'5/12': '41.666667%',
	'6/12': '50%',
	'7/12': '58.333333%',
	'8/12': '66.666667%',
	'9/12': '75%',
	'10/12': '83.333333%',
	'11/12': '91.666667%',
	'1/20': '5%',
	'2/20': '10%',
	'3/20': '15%',
	'4/20': '20%',
	'5/20': '25%',
	'6/20': '30%',
	'7/20': '35%',
	'8/20': '40%',
	'9/20': '45%',
	'10/20': '50%',
	'11/20': '55%',
	'12/20': '60%',
	'13/20': '65%',
	'14/20': '70%',
	'15/20': '75%',
	'16/20': '80%',
	'17/20': '85%',
	'18/20': '90%',
	'19/20': '95%',
};
module.exports = spacings;
