{
	"name": "valk-nx",
	"version": "1.53.0",
	"description": "NX Monorepo for van der Valk front-end applications",
	"license": "MIT",
	"browser": {
		"fs": false,
		"path": false,
		"os": false
	},
	"scripts": {
		"affected:apps": "nx affected:apps",
		"affected:build": "nx affected:build",
		"affected:build:ci": "nx affected --target='build-ci' --parallel=1",
		"affected:ci:bulk": "nx affected --target=stylelint:ci,lint,test:ci,test-storybook,test-vr-ci --parallel=3",
		"affected:ci:e2e": "nx affected --target=e2e:ci:shard-1,e2e:ci:shard-1-15,e2e:ci:shard-2,e2e:ci:shard-2-15,e2e:ci:shard-3,e2e:ci:shard-4,e2e:ci:shard-5,e2e:ci:shard-6,e2e:ci:shard-7,e2e:ci:shard-8,e2e:ci:shard-9,e2e:ci:shard-10,e2e:ci:shard-11,e2e:ci:shard-12,e2e:ci:shard-13,e2e:ci:shard-14,e2e:ci:shard-15,e2e:ci:shard-1-5,e2e:ci:shard-2-5,e2e:ci:shard-3-5,e2e:ci:shard-4-5,e2e:ci:shard-5-5 --parallel=1",
		"affected:docker": "nx affected --target='docker-build' --parallel=1 --CONF=$CONF --ENVIRONMENT=$ENVIRONMENT",
		"affected:deploy": "nx affected --target='deploy' --parallel=1 ",
		"affected:dep-graph": "nx affected:dep-graph ",
		"affected:e2e": "nx affected:e2e --parallel=1",
		"affected:libs": "nx affected:libs",
		"affected:lint": "nx affected --target=stylelint --parallel=3 && nx affected:lint --parallel=3 && npx tsc --noemit",
		"affected:lint:ci": "nx affected --target=stylelint:ci --parallel=3 && nx affected --target='lint:ci' --parallel=3 && npx tsc --noemit",
		"affected:test": "nx affected:test",
		"affected:test:ci": "nx affected --target=test:ci",
		"affected:test-vr:ci": "start-server-and-test start:storybook-dist 4400 'nx affected --target=test-vr-ci --shard=$SHARD/5'",
		"affected:test-vr-update:ci": "start-server-and-test start:storybook-dist 4400 'nx affected --target=test-vr-update-ci '",
		"certificate:generate": "cd tools/generators/ssl && bash generateCertificateOSX.sh",
		"certificate:copy": "cp -a ./ssl/default/. ./ssl/.",
		"clean:reports": "find test-reports -type d -empty -delete",
		"dep-graph": "nx dep-graph",
		"format:check": "nx format:check",
		"format:staged": "lint-staged",
		"format:write": "nx format:write",
		"prepare": "husky install",
		"ng": "nx",
		"set-base": "node ./tools/affected/set-base.js >> .env",
		"set-base:debug": "DEBUG=1 node ./tools/affected/set-base.js",
		"start-agent": "nx-cloud start-agent",
		"start:storybook-dist": "http-server dist/storybook/storybook-bundle -s -p 4400",
		"test-storybook": "test-storybook --url http://localhost:4400 --config-dir libs/storybook-bundle/.storybook",
		"test-vr": "start-server-and-test 'nx run storybook-bundle:build-storybook --skip-nx-cache && http-server dist/storybook/storybook-bundle -s -p 4400' 4400 \"docker run --rm --name playwright-vr -p 4400:4400 -v $(pwd):/work/ -w /work/ mcr.microsoft.com/playwright:v1.45.3-jammy npx playwright test --config=${CONFIG}\"",
		"test-vr-update": "start-server-and-test 'nx run storybook-bundle:build-storybook --skip-nx-cache && http-server dist/storybook/storybook-bundle -s -p 4400' 4400 \"docker run --rm --name playwright-vr -p 4400:4400 -v $(pwd):/work/ -w /work/ mcr.microsoft.com/playwright:v1.45.3-jammy npx playwright test --config=${CONFIG} --update-snapshots\"",
		"update": "nx migrate latest"
	},
	"private": true,
	"dependencies": {
		"@angular/animations": "18.0.4",
		"@angular/cdk": "18.0.4",
		"@angular/common": "18.0.4",
		"@angular/compiler": "18.0.4",
		"@angular/core": "18.0.4",
		"@angular/elements": "18.0.4",
		"@angular/forms": "18.0.4",
		"@angular/platform-browser": "18.0.4",
		"@angular/platform-browser-dynamic": "18.0.4",
		"@angular/platform-server": "18.0.4",
		"@angular/router": "18.0.4",
		"@angular/service-worker": "18.0.4",
		"@angular/ssr": "18.0.4",
		"@elastic/apm-rum-angular": "3.0.4",
		"@elastic/ecs-pino-format": "1.5.0",
		"@ng-select/ng-select": "13.4.1",
		"@ngrx/component": "18.0.1",
		"@ngrx/component-store": "18.0.1",
		"@ngrx/effects": "18.0.1",
		"@ngrx/entity": "18.0.1",
		"@ngrx/router-store": "18.0.1",
		"@ngrx/store": "18.0.1",
		"@ngrx/store-devtools": "18.0.1",
		"@ngx-formly/core": "6.3.6",
		"@ngx-translate/core": "15.0.0",
		"@ngx-translate/http-loader": "8.0.0",
		"@nx/angular": "19.5.3",
		"@popperjs/core": "2.11.8",
		"@storyblok/field-plugin": "1.1.2",
		"@storyblok/js": "3.0.8",
		"@storybook/core-server": "8.2.6",
		"@swc/helpers": "0.5.12",
		"angular-svg-icon": "18.0.1",
		"compression": "1.7.4",
		"dayjs": "1.11.12",
		"express": "4.19.2",
		"flatpickr": "4.6.13",
		"jwt-decode": "4.0.0",
		"marked": "13.0.2",
		"ng-dynamic-component": "10.7.0",
		"ng-recaptcha": "13.2.1",
		"ngx-markdown": "18.0.0",
		"ngx-mask": "17.0.4",
		"ngx-toastr": "19.0.0",
		"pino-http": "10.2.0",
		"radash": "12.1.0",
		"rxjs": "7.8.1",
		"seedrandom": "3.0.5",
		"storybook-addon-angular-router": "1.10.0",
		"storybook-addon-pseudo-states": "3.1.1",
		"swiper": "11.1.7",
		"tslib": "2.6.3",
		"xml-js": "1.6.11",
		"zone.js": "0.14.8"
	},
	"devDependencies": {
		"@angular-devkit/architect": "0.1801.2",
		"@angular-devkit/build-angular": "18.1.2",
		"@angular-devkit/core": "18.1.2",
		"@angular-devkit/schematics": "18.1.2",
		"@angular-eslint/eslint-plugin": "18.0.1",
		"@angular-eslint/eslint-plugin-template": "18.0.1",
		"@angular-eslint/template-parser": "18.0.1",
		"@angular/cli": "18.0.4",
		"@angular/compiler-cli": "18.0.4",
		"@angular/language-service": "18.0.4",
		"@axe-core/playwright": "4.9.1",
		"@cucumber/cucumber": "10.8.0",
		"@etchteam/storybook-addon-status": "5.0.0",
		"@ng-apimock/base-client": "3.3.1",
		"@ng-apimock/core": "3.11.2",
		"@ng-apimock/dev-interface": "3.5.1",
		"@nx/devkit": "19.5.3",
		"@nx/eslint": "19.5.3",
		"@nx/eslint-plugin": "19.5.3",
		"@nx/jest": "19.5.3",
		"@nx/js": "19.5.3",
		"@nx/node": "19.5.3",
		"@nx/playwright": "19.5.3",
		"@nx/plugin": "19.5.3",
		"@nx/storybook": "19.5.3",
		"@nx/vite": "19.5.3",
		"@nx/workspace": "19.5.3",
		"@playwright/test": "1.45.3",
		"@schematics/angular": "18.0.4",
		"@storybook/addon-a11y": "8.2.6",
		"@storybook/addon-actions": "8.2.6",
		"@storybook/addon-essentials": "8.2.6",
		"@storybook/addon-interactions": "8.2.6",
		"@storybook/addon-themes": "8.2.6",
		"@storybook/angular": "8.2.6",
		"@storybook/manager-api": "8.2.6",
		"@storybook/preview-api": "8.2.6",
		"@storybook/test": "8.2.6",
		"@storybook/test-runner": "0.19.1",
		"@storybook/theming": "8.2.6",
		"@swc-node/register": "1.10.9",
		"@swc/cli": "0.4.0",
		"@swc/core": "1.7.1",
		"@testing-library/jest-dom": "6.4.8",
		"@tokens-studio/sd-transforms": "1.2.0",
		"@types/compression": "1.7.5",
		"@types/express": "4.17.21",
		"@types/jest": "29.5.12",
		"@types/node": "20.14.12",
		"@types/seedrandom": "3.0.8",
		"@typescript-eslint/eslint-plugin": "7.17.0",
		"@typescript-eslint/parser": "7.17.0",
		"@typescript-eslint/utils": "7.17.0",
		"@vitest/coverage-istanbul": "2.0.4",
		"@vitest/ui": "2.0.4",
		"autoprefixer": "10.4.19",
		"browser-sync": "3.0.2",
		"eslint": "8.57.0",
		"eslint-config-prettier": "9.1.0",
		"eslint-plugin-playwright": "1.6.2",
		"eslint-plugin-simple-import-sort": "12.1.1",
		"eslint-plugin-storybook": "0.8.0",
		"eslint-plugin-tailwindcss": "3.17.4",
		"express-openapi-validate": "0.6.1",
		"global": "4.4.0",
		"http-server": "14.1.1",
		"husky": "9.0.11",
		"jest": "29.7.0",
		"jest-environment-jsdom": "29.7.0",
		"jest-environment-node": "29.7.0",
		"jest-preset-angular": "14.2.0",
		"jsdom": "24.1.1",
		"jsonc-eslint-parser": "2.4.0",
		"lint-staged": "15.2.7",
		"morgan": "1.10.0",
		"nodemon": "3.1.4",
		"nx": "19.5.3",
		"playwright-bdd": "6.5.2",
		"postcss": "8.4.39",
		"prettier": "3.3.3",
		"prettier-plugin-tailwindcss": "0.6.5",
		"replace-in-file": "7.2.0",
		"request": "2.88.2",
		"rimraf": "5.0.7",
		"sass": "1.77.8",
		"sass-loader": "14.2.1",
		"start-server-and-test": "2.0.4",
		"storybook": "8.2.6",
		"style-dictionary": "4.0.1",
		"stylelint": "16.7.0",
		"stylelint-config-idiomatic-order": "10.0.0",
		"stylelint-config-sass-guidelines": "12.0.0",
		"stylelint-config-standard": "36.0.1",
		"tailwindcss": "3.4.6",
		"timeshift-js": "1.2.0",
		"ts-jest": "29.2.3",
		"ts-node": "10.9.2",
		"typescript": "5.4.5",
		"vite": "5.3.4",
		"vite-plugin-css-injected-by-js": "3.5.1",
		"vitest": "2.0.4"
	},
	"overrides": {
		"piscina": "4.5.1"
	},
	"nx": {
		"includedScripts": []
	}
}
