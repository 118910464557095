import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AlertModule } from '@valk-nx/components/ui-alert/src/lib/alert.module';
import { DropdownComponent } from '@valk-nx/components/ui-dropdown/src/lib/dropdown';
import { InputComponent } from '@valk-nx/components/ui-input/src/lib/input';
import { LabelComponent } from '@valk-nx/components/ui-label/src/lib/label/label';
import { HotelByDealCardModule } from '@valk-nx/compositions/ui-hotel-by-deal-card/src/lib/hotel-by-deal-card.component.module';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';

import { HotelByDealCardListComponent } from './hotel-by-deal-card-list.component';

@NgModule({
	declarations: [HotelByDealCardListComponent],
	exports: [HotelByDealCardListComponent],
	imports: [
		AngularSvgIconModule.forRoot(),
		AlertModule,
		CommonModule,
		LabelComponent,
		InputComponent,
		DropdownComponent,
		HotelByDealCardModule,
		SharedModule,
		TranslateModule.forChild({}),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HotelByDealCardListModule {}
