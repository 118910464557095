import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';

import { AlertComponent } from './alert.component';
import { AlertToastrComponent } from './alert-toastr.component';

@NgModule({
	declarations: [AlertComponent, AlertToastrComponent],
	exports: [AlertComponent],
	imports: [
		CommonModule,
		MarkdownModule.forRoot({}),
		ToastrModule.forRoot(),
		AngularSvgIconModule.forRoot(),
	],
	providers: [provideHttpClient()],
})
export class AlertModule {}
