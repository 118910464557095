const config = require('./libs/tailwind/src/configs/index');

/** @type {import("@types/tailwindcss/tailwind-config").Config } */
module.exports = {
	content: ['src/**/*.{html,ts}'],
	theme: {
		extend: {
			animation: {
				'fade-in': 'fade-in 0.3s ease-out',
				loading: 'loading 2000ms infinite',
			},
			aspectRatio: {
				classic: '4 / 3',
				film: '3 / 2',
				anamorphic: '21 / 9',
				'ultra-wide': '32 / 9',
			},
			borderRadius: {
				input: 'var(--inputs-border-radius, 0.25rem)',
			},
			boxShadow: {
				border: '0 0 0 1px',
				'item-focus': '0px 0px 8px 0px',
				'inner-sm': 'inset 0px 2px 1px',
			},
			content: {
				empty: '""',
				unset: 'unset',
			},
			height: {
				'0%': '0%',
				inherit: 'inherit',
			},
			keyframes: {
				loading: {
					from: { backgroundPositionX: '100%' },
					to: { backgroundPositionX: '-100%' },
				},
				'fade-in': {
					'0%': { opacity: '0%' },
					'100%': { opacity: '100%' },
				},
			},
			margin: config.spacings,
			maxWidth: {
				'1/2': '50%',
			},
			padding: config.spacings,
			rotate: {
				135: '135deg',
				225: '225deg',
				315: '315deg',
			},
			screens: {
				'max-md': { max: '767px' },
				xl: '1248px',
				'3xl': '1728px',
			},
			textColor: config.textColors,
			transitionProperty: {
				sheet: 'top, right, bottom, left, auto',
				width: 'width',
			},
			transitionTimingFunction: {
				basic: '0.4s ease',
			},
			width: {
				'1/10': '10%',
				'2/10': '20%',
				'3/10': '30%',
				'4/10': '40%',
				'5/10': '50%',
				'6/10': '60%',
				'7/10': '70%',
				'8/10': '80%',
				'9/10': '90%',
				inherit: 'inherit',
			},
		},
		colors: config.colors,
		fontFamily: {
			'sans-serif': ['Source Sans Pro', 'Arial', 'sans-serif'],
			logo: ['Melbourne', 'Helvetica', 'Arial', 'sans-serif'],
			display: ['Raleway', 'Helvetica', 'Arial', 'sans-serif'],
			extra: ['PT Sans', 'Arial', 'sans-serif'],
		},
		fontSize: {
			xxs: ['0.625rem', { lineHeight: '1' }],
			xs: ['0.75rem', { lineHeight: '1.625' }],
			sm: ['0.875rem', { lineHeight: '1.625' }],
			base: ['1rem', { lineHeight: '1.625' }],
			lg: ['1.125rem', { lineHeight: '1.5' }],
			xl: ['1.25rem', { lineHeight: '1.375' }],
			'2xl': ['1.5rem', { lineHeight: '1.25' }],
			'3xl': ['1.875rem', { lineHeight: '1.25' }],
			'4xl': ['2.25rem', { lineHeight: '1' }],
			'5xl': ['2.5rem', { lineHeight: '1' }],
			'6xl': ['3rem', { lineHeight: '1' }],
			inherit: ['inherit', { lineHeight: 'inherit' }],
		},
	},
	safelist: config.safelist,
	plugins: [
		require('./libs/tailwind/src/plugins/typography/typography').default,
		require('./libs/tailwind/src/plugins/children/children').default,
		require('./libs/tailwind/src/plugins/not-selector/not-selector')
			.default,
		require('./libs/tailwind/src/plugins/overlay/overlay').default,
		require('./libs/tailwind/src/plugins/typography/base').default,
		require('./libs/tailwind/src/plugins/typography/spacings').default,
		require('./libs/tailwind/src/plugins/button/button').default,
		require('./libs/tailwind/src/plugins/card/card').default,
		require('./libs/tailwind/src/plugins/input-control/input-control')
			.default,
		require('./libs/tailwind/src/plugins/elevation/elevation').default,
		require('./libs/tailwind/src/plugins/divider/divider').default,
		require('./libs/tailwind/src/plugins/footer/footer').default,
		require('./libs/tailwind/src/plugins/form/form').default,
		require('./libs/tailwind/src/plugins/list/list').default,
		require('./libs/tailwind/src/plugins/running-head/running-head')
			.default,
		require('./libs/tailwind/src/plugins/text-border/text-border').default,
		require('./libs/tailwind/src/plugins/text-link/text-link').default,
		require('./libs/tailwind/src/plugins/heading-group/heading-group')
			.default,
		require('./libs/tailwind/src/plugins/icon-container/icon-container')
			.default,
		require('./libs/tailwind/src/plugins/tag/tag').default,
		require('./libs/tailwind/src/plugins/pricing/pricing').default,
	],
};
