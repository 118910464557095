import { CommonModule, isPlatformServer } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { Observable, take } from 'rxjs';

import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { CarouselItem } from '@valk-nx/components/ui-carousel/src/lib/carousel.interface';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { RoomFacilitiesComponent } from '@valk-nx/components/ui-room-facilities/src/lib/room-facilities.component';
import { AvailabilityWidgetComponent } from '@valk-nx/compositions/ui-availability-widget/src/lib/availability-widget.component';
import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { negativeHeader$ } from '@valk-nx/core/lib/core';
import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';
import { AccommodationTypeModule } from '@valk-nx/core/lib/pipes/accommodation-type/accommodation-type.module';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import {
	ContentService,
	HOTEL_SLUG,
	RoomData,
} from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	selector: 'sb-room-detail',
	templateUrl: './room-detail.component.html',
	standalone: true,
	imports: [
		AccommodationTypeModule,
		AvailabilityWidgetComponent,
		CarouselComponent,
		CommonModule,
		MarkdownModule,
		PersuadeCardComponent,
		PricingComponent,
		ReadMoreComponent,
		RoomFacilitiesComponent,
		StoryblokDirective,
		TranslateModule,
	],
})
export class RoomDetailComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) canonical!: string;

	slug!: TranslatedSlug;
	roomData: RoomData;
	images: CarouselItem[] = [];

	translatedSlugs$: Observable<TranslatedSlug[]>;

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private metadataService: MetadataService,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly cd: ChangeDetectorRef,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();
		this.translatedSlugs$ =
			this.sbService.translatedSlugs$.pipe(takeUntilDestroyed());
	}

	ngOnInit() {
		negativeHeader$.next(false);
		this.translatedSlugs$.subscribe((translatedSlug) => {
			this.slug =
				translatedSlug.find((x) => x.selected) || translatedSlug[0];

			this.contentService
				.getRoom(
					this.slug.path.split('/').pop(),
					this.slug.lang.toLowerCase(),
				)
				.pipe(take(1))
				.subscribe((response: HttpResponse<HALResponse<RoomData>>) => {
					this.roomData = response.body?.data;

					this.updateMeta(this.roomData);

					this.images = this.roomData.images.map(
						(image: { src: string; alt: string }) => {
							return {
								image: {
									src: image.src,
									altText: image.alt,
								},
								heading: null,
								subHeading: null,
								link: null,
							};
						},
					);

					if (this.images.length > 0) {
						negativeHeader$.next(true);
					}
					this.cd.markForCheck();
				});
		});
	}

	updateMeta(roomData: RoomData) {
		if (isPlatformServer(this.platformId)) {
			this.metadataService.updateMetadata(
				{
					hideFromSiteMap: false,
					metaDescription: roomData.meta.description,
					metaTitle: roomData.meta.title,
					openGraphDescription: roomData.meta.description,
					openGraphTitle: roomData.meta.title,
					openGraphImage: {
						file: roomData.images[0]?.src,
						alt: roomData.images[0].alt,
					},
					openGraphType: 'website',
					robotsNoFollow: false,
					robotsNoIndex: false,
				},
				true,
			);
		}
	}
}
