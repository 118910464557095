<vp-popover
	*ngrxLet="{
		isSmallMobile: isSmallMobile$,
		isSmallTablet: isSmallTablet$,
		prettyStartDate: prettyStartDate$,
		prettyEndDate: prettyEndDate$,
		numberOfNights: numberOfNights$,
		selectedDates: selectedDates$,
	} as model"
	[alignment]="popoverAlignment"
	[boundaryTargetId]="boundaryTargetId"
	[disabled]="disabled"
	[isFullHeight]="model.isSmallMobile"
	[overruleCreateInstance]="true"
	[popoverOffset]="popoverOffset"
	[showHeader]="false"
	[showPopover]="isPopoverOpen"
	(isOpen)="popoverStateChanged($event)"
	className="overflow-y-scroll !p-4 sm:!p-6"
	popoverId="popover-date-range-picker"
	triggerId="trigger-button-date-range-picker"
>
	<div
		#popoverTrigger
		class="flex flex-row"
		data-test-id="date-range-picker"
		id="trigger-button-date-range-picker"
		valkCoreClicked
	>
		<ng-container
			[ngTemplateOutlet]="datePickerInput"
			[ngTemplateOutletContext]="{
				inputId: 'trigger-button-start-' + startInputConfig.name,
				labelSettings: startLabelSettings,
				initialValue: model.prettyStartDate,
				inputConfig: startInputConfig,
				borderRadiusStyle: 'rounded-l',
				testId: 'checkin-input',
			}"
		/>
		<ng-container
			[ngTemplateOutlet]="datePickerInput"
			[ngTemplateOutletContext]="{
				inputId: 'trigger-button-end-' + startInputConfig.name,
				labelSettings: endLabelSettings,
				initialValue: model.prettyEndDate,
				inputConfig: endInputConfig,
				borderRadiusStyle: 'rounded-r',
				class: '-ml-px',
				testId: 'checkout-input',
			}"
			data-test-id="checkout-input"
		/>
	</div>
	@if (model.isSmallTablet) {
		<vp-popover-header-title>
			<span class="text-lg font-semibold text-dark-primary">{{
				'global.choose-dates' | translate
			}}</span>
		</vp-popover-header-title>
	}

	<vp-popover-content>
		@if (model.isSmallTablet) {
			<div class="mb-4">
				<div class="flex flex-row">
					<ng-container
						[ngTemplateOutlet]="datePickerInput"
						[ngTemplateOutletContext]="{
							inputId:
								'popover-input-start-' + startInputConfig.name,
							labelSettings: startLabelSettings,
							initialValue: model.prettyStartDate,
							inputConfig: startInputConfig,
							borderRadiusStyle: 'rounded-l',
						}"
					/>
					<ng-container
						[ngTemplateOutlet]="datePickerInput"
						[ngTemplateOutletContext]="{
							inputId:
								'popover-input-end-' + startInputConfig.name,
							labelSettings: endLabelSettings,
							initialValue: model.prettyEndDate,
							inputConfig: endInputConfig,
							borderRadiusStyle: 'rounded-r',
							class: '-ml-px',
						}"
					/>
				</div>
			</div>
		}
		<div
			[class.datepicker--hidden]="!isPopoverOpen"
			[id]="'popover-input-date-range-picker'"
		>
			<vp-calendar
				[calendarData]="filteredCalendarData$ | async"
				[config]="config$ | async"
				[isLoading]="isLoading"
				[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
				(datePickerEmitter)="onDatePickerEmitter($event)"
				(emitHasError)="onEmitHasError($event)"
				(emitMonthChange)="onEmitMonthChange($event)"
			/>
		</div>
		@if (showButtonOnMobile && model.isSmallTablet) {
			<div class="mt-4 flex flex-col gap-2">
				<hr class="-mx-4 border-t border-ui-100 sm:-mx-6" />
				<div class="text-center">
					<div class="justify-center text-center">
						@switch (model.selectedDates.length) {
							@case (0) {
								<div
									class="mr-auto text-base text-dark-tertiary"
								>
									{{
										'global.choose-arrival-date' | translate
									}}
								</div>
							}
							@case (1) {
								<div
									class="mr-auto text-base text-dark-tertiary"
								>
									{{
										'global.choose-departure-date'
											| translate
									}}
								</div>
							}
							@case (2) {
								<div class="text-base text-dark-tertiary">
									{{ model.numberOfNights }}
									{{
										(model.numberOfNights === 1
											? 'global.night'
											: 'global.nights'
										) | translate
									}}
								</div>
							}
						}
					</div>
				</div>

				<button
					[disabled]="!model.prettyStartDate || !model.prettyEndDate"
					(click)="emitShowAvailabilityButton.emit($event)"
					class="btn btn-primary w-full"
					data-test-id="show-availability-button"
				>
					{{ 'global.show-availability' | translate }}
				</button>
			</div>
		}
	</vp-popover-content>
</vp-popover>

<ng-template
	#datePickerInput
	let-borderRadiusStyle="borderRadiusStyle"
	let-class="class"
	let-initialValue="initialValue"
	let-inputConfig="inputConfig"
	let-inputId="inputId"
	let-labelSettings="labelSettings"
	let-testId="testId"
>
	<vp-label
		[attr.data-test-id]="testId"
		[inputName]="inputConfig.name"
		[labelText]="labelSettings.text"
		[ngClass]="class"
		[position]="labelSettings.position"
		class="z-0 w-1/2 focus-within:z-10"
	>
		<vp-input
			[autocomplete]="inputConfig.autocomplete"
			[defaultStyle]="true"
			[disabled]="inputConfig.disabled"
			[hasError]="isPastDate || inputConfig.hasError"
			[icon]="inputConfig.icon"
			[iconPosition]="inputConfig.iconPosition"
			[initialValue]="initialValue"
			[inputId]="inputId"
			[isValid]="inputConfig.isValid"
			[name]="inputConfig.name"
			[placeholder]="inputConfig.placeholder"
			[readonly]="true"
			[required]="inputConfig.required"
		/>
	</vp-label>
</ng-template>
