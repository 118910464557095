<div>
	@for (blok of content; track blok) {
		<div>
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		</div>
	}
</div>
<section class="bg-accent-10 px-3 py-8 md:px-8 md:py-12 lg:py-16 2xl:px-10">
	<div
		class="mx-auto flex max-w-screen-2xl flex-col gap-y-4 md:gap-y-6 2xl:gap-y-8"
	>
		<div class="flex w-full flex-col gap-8 md:mx-auto lg:w-10/12">
			<h3 class="running-head mb-0" data-test-class="running-head">
				{{ 'global.packages-label' | translate }}
			</h3>
			@for (package of packages; track $index) {
				<vp-persuade-card
					[content]="package.bulletContent"
					[ctaText]="'storyblok.hotel.package-card.view' | translate"
					[ctaUrl]="currentUrl + '/' + package.slug"
					[currency]="package.price.currency"
					[facilities]="package.facilities"
					[images]="package.images"
					[price]="package.price.amount"
					[title]="package.name"
				/>
			}
		</div>
	</div>
</section>
