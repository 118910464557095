import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';
import { Observable } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	selector: 'sb-root',
	templateUrl: './root.component.html',
	standalone: true,
	imports: [CommonModule, DynamicModule, StoryblokDirective],
})
export class SbRootComponent {
	readonly story$: Observable<ISbStoryData>;
	components = Components;

	constructor(private sbService: StoryblokService) {
		this.story$ = this.sbService.story$;
	}
}
