<vp-availability-widget-input
	*ngrxLet="isSmallTablet$ as isSmallTablet"
	[className]="className"
	[excludeFromClosingRegex]="excludeFromClosingRegex"
	[header]="'global.room-layout' | translate"
	[iconName]="_isOpen ? 'icon-chevron-up' : 'icon-chevron-down'"
	[isOpen]="_isOpen"
	[label]="'global.room-layout' | translate"
	[value]="(roomLabel | lowercase) + ', ' + personLabel"
	(emitIsOpen)="popoverStateChanged($event)"
	inputName="availability-occupancy"
>
	<div
		[ngClass]="
			occupancy?.length === 1 && isSmallTablet
				? 'justify-between'
				: 'mr-9'
		"
		class="mb-2 flex"
	>
		@if (!isSmallTablet) {
			<div class="mr-3 w-14">
				<span
					class="text-base font-semibold text-dark-primary md:text-lg"
					data-test-id="room-label"
				>
					{{ 'global.room' | translate }}
				</span>
			</div>
		}
		<div class="mr-3 flex w-28 flex-col">
			<span
				class="text-base font-semibold text-dark-primary md:text-lg"
				data-test-id="adults-label"
			>
				{{ 'components.occupancy.adults.label' | translate }}
			</span>
			<span
				class="text-sm text-dark-tertiary md:text-base"
				data-test-id="adults-helper"
			>
				{{ 'global.older-than' | translate: { age: 12 } }}
			</span>
		</div>
		<div class="mr-3 flex w-28 flex-col">
			<span
				class="text-base font-semibold text-dark-primary md:text-lg"
				data-test-id="children-label"
			>
				{{ 'components.occupancy.children.label' | translate }}
			</span>
			<span
				class="text-sm text-dark-tertiary md:text-base"
				data-test-id="children-helper"
			>
				{{ 'global.till-text' | translate: { min: 4, max: 12 } }}
			</span>
		</div>
		<div class="flex w-28 flex-col">
			<span
				class="text-base font-semibold text-dark-primary md:text-lg"
				data-test-id="infants-label"
			>
				{{ 'components.occupancy.infants.label' | translate }}
			</span>
			<span
				class="text-sm text-dark-tertiary md:text-base"
				data-test-id="infants-helper"
			>
				{{ 'global.till-text' | translate: { min: 0, max: 3 } }}
			</span>
		</div>
	</div>
	<div>
		@for (occ of occupancy; track occ; let i = $index) {
			<div
				class="mb-6 flex justify-between md:justify-normal"
				data-test-class="occupancy-row"
			>
				@if (!isSmallTablet) {
					<div
						class="mr-3 w-14 self-center text-center"
						data-test-id="room-index"
					>
						{{ i + 1 }}
					</div>
				}
				<div class="mr-3 w-28" data-test-id="adults-dropdown">
					<vp-dropdown
						[centered]="true"
						[dropdownPosition]="isSmallTablet ? 'top' : 'bottom'"
						[inputId]="'occupancy-' + i + '-adults'"
						[label]="
							('components.occupancy.adults.label' | translate) +
							' ' +
							(i + 1)
						"
						[placeholder]="selectableData.adults[0].value"
						[selectData]="selectableData.adults"
						[value]="occ.adults.toString()"
						(selectedValue)="
							selectDropdownValue($event, 'adults', i)
						"
						variant="integrated"
					/>
				</div>
				<div class="mr-3 w-28" data-test-id="children-dropdown">
					<vp-dropdown
						[centered]="true"
						[dropdownPosition]="isSmallTablet ? 'top' : 'bottom'"
						[inputId]="'occupancy-' + i + '-children'"
						[label]="
							('components.occupancy.children.label'
								| translate) +
							' ' +
							(i + 1)
						"
						[placeholder]="selectableData.children[0].value"
						[selectData]="selectableData.children"
						[value]="occ.children.toString()"
						(selectedValue)="
							selectDropdownValue($event, 'children', i)
						"
						variant="integrated"
					/>
				</div>

				<div
					[ngClass]="{ 'mr-3': occupancy.length !== 1 }"
					class="w-28"
					data-test-id="infants-dropdown"
				>
					<vp-dropdown
						[centered]="true"
						[dropdownPosition]="isSmallTablet ? 'top' : 'bottom'"
						[inputId]="'occupancy-' + i + '-infants'"
						[label]="
							('components.occupancy.infants.label' | translate) +
							' ' +
							(i + 1)
						"
						[placeholder]="selectableData.infants[0].value"
						[selectData]="selectableData.infants"
						[value]="occ.infants.toString()"
						(selectedValue)="
							selectDropdownValue($event, 'infants', i)
						"
						variant="integrated"
					/>
				</div>

				@if (occupancy.length > 1) {
					<button
						(click)="onRemoveRoom($event, i)"
						data-test-id="remove-row-button"
					>
						<svg-icon
							class="block size-6"
							src="assets/icons/icon-cross.svg"
						/>
					</button>
				}
			</div>
		}
	</div>

	@if (occupancy.length < maxRooms) {
		<button
			(click)="onAddRoom($event)"
			class="text-link inline-flex no-underline"
			data-test-id="occupancy-add-room"
		>
			<svg-icon class="size-6" src="assets/icons/icon-plus.svg" />
			{{ 'global.add-room' | translate }}
		</button>
	}
	<div class="mt-2 flex flex-col gap-2" widget-input-footer>
		<div class="text-center">
			<div class="justify-center text-center">
				<span class="text-sm text-dark-tertiary">{{
					roomLabel + ', ' + personLabel
				}}</span>
			</div>
		</div>

		<button
			(click)="emitChooseDates.emit($event)"
			class="btn btn-primary mt-auto w-full"
			data-test-id="choose-dates-button"
		>
			{{ 'global.choose-dates' | translate }}
		</button>
	</div>
</vp-availability-widget-input>
