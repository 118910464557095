import plugin from 'tailwindcss/plugin';

const setupSelector = (
	theme,
	topMobile,
	bottomMobile,
	topDesktop,
	bottomDesktop,
) => {
	return {
		marginTop: theme(topMobile),
		marginBottom: theme(bottomMobile),
		'&:first-child': {
			marginTop: theme('margin.0'),
		},
		'&:last-child': {
			marginBottom: theme('margin.0'),
		},
		[`@media (min-width: ${theme('screens.md')})`]: {
			marginTop: theme(topDesktop),
			marginBottom: theme(bottomDesktop),
		},
	};
};

const setupListSelector = (
	theme,
	topMobile,
	leftMobile,
	topDesktop,
	leftDesktop,
) => {
	return {
		marginTop: theme(topMobile),
		marginLeft: theme(leftMobile),
		'&:first-child': {
			marginTop: theme('margin.0'),
		},
		'&:last-child': {
			marginBottom: theme('margin.0'),
		},
		[`@media (min-width: ${theme('screens.md')})`]: {
			marginTop: theme(topDesktop),
			marginLeft: theme(leftDesktop),
		},
	};
};

const typographySpacings = plugin(function ({ addBase, theme }) {
	const selectors = {
		h1: setupSelector(
			theme,
			'margin.14',
			'margin.1',
			'margin.16',
			'margin.1',
		),
		h2: setupSelector(
			theme,
			'margin.12',
			'margin.1',
			'margin.14',
			'margin.1',
		),
		h3: setupSelector(
			theme,
			'margin.10',
			'margin[0.5]',
			'margin.12',
			'margin[0.5]',
		),
		h4: setupSelector(
			theme,
			'margin.9',
			'margin[0.5]',
			'margin.10',
			'margin[0.5]',
		),
		h5: setupSelector(
			theme,
			'margin.8',
			'margin.0',
			'margin.9',
			'margin.0',
		),
		h6: setupSelector(
			theme,
			'margin.7',
			'margin.0',
			'margin.8',
			'margin.0',
		),
		p: setupSelector(theme, 'margin.3', 'margin.0', 'margin.4', 'margin.0'),
		hr: setupSelector(
			theme,
			'margin.6',
			'margin.0',
			'margin.10',
			'margin.0',
		),
		'> ul': setupListSelector(
			theme,
			'margin.3',
			'margin.3',
			'margin.4',
			'margin.4',
		),
		'> ol': setupListSelector(
			theme,
			'margin.3',
			'margin.3',
			'margin.4',
			'margin.4',
		),
	};

	const blockquote = {
		marginTop: theme('margin.8'),
		marginBottom: theme('margin.4'),
		marginLeft: theme('margin.3'),
		marginRight: theme('margin.3'),
		'&:first-child': {
			marginTop: theme('margin.0'),
		},
		'&:last-child': {
			marginBottom: theme('margin.0'),
		},
		[`@media (min-width: ${theme('screens.md')})`]: {
			marginTop: theme('margin.16'),
			marginBottom: theme('margin.8'),
			marginLeft: theme('margin.8'),
			marginRight: theme('margin.0'),
		},
	};

	const links = {
		'& a': {
			color: theme('colors.select.100'),
			textDecoration: 'underline',
			cursor: 'pointer',
			'&:active': {
				color: theme('colors.select.200'),
			},
			'&:visited': {
				color: theme('colors.select.100'),
			},
		},
	};

	addBase({
		markdown: { ...selectors, blockquote },
		'.richtext': { ...selectors, blockquote, ...links },
	});
});

export default typographySpacings;
