import { AbstractControl } from '@angular/forms';

export function phoneValidator(
	control: Pick<AbstractControl<string>, 'value'>,
) {
	const withoutSpaces = control.value?.replace(/\s+/g, '');

	/* eslint-disable no-useless-escape */
	return /^\(?\+?[\d\(\)-]{6,20}$/gm.test(withoutSpaces);
}
