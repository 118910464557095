<section
	*ngrxLet="isMobile$ as isMobile"
	[attr.aria-label]="title"
	class="card card-xs"
	data-test-class="persuade-card"
	role="complementary"
>
	<div [ngClass]="{ 'md:flex-row': !useVerticalCard }" class="flex flex-col">
		@if (images && images.length > 0) {
			<vp-carousel-modal
				[images]="images"
				class="relative"
				className="h-full"
			>
				<div class="h-full w-28 min-w-full md:w-44 lg:w-64">
					<vp-image
						[image]="{
							src: images[0].src,
							altText: images[0].alt,
							width: 652,
							height: 460,
						}"
						className="h-full w-full rounded-sm object-cover"
					/>
					<div
						class="absolute bottom-0 right-0 rounded-br-sm rounded-tl-2xl bg-black/50 pb-2 pl-3 pr-2 pt-3"
					>
						<svg-icon
							class="block size-4 text-white"
							src="assets/icons/icon-looking-glass.svg"
							svgAriaLabel="Open"
						/>
					</div>
				</div>
			</vp-carousel-modal>
		}

		<div
			[ngClass]="useVerticalCard ? 'md:pl-3' : 'md:pl-6'"
			class="flex w-full px-2 py-3 md:py-3 md:pr-3"
		>
			<div
				[ngClass]="{
					'md:flex-row md:justify-between': !useVerticalCard,
				}"
				class="flex w-full flex-1 flex-col gap-5"
			>
				<div
					[ngClass]="{
						'md:justify-between': !useVerticalCard,
					}"
					class="flex flex-col gap-5"
				>
					<div>
						<header>
							<h3
								class="heading-group"
								data-test-id="persuade-card-title"
							>
								<a [routerLink]="readMoreUrl">{{ title }}</a>
							</h3>
							@if (overline) {
								<p
									class="heading-group-overline"
									role="doc-subtitle"
								>
									{{ overline }}
								</p>
							}
						</header>
						@if (facilities) {
							<vp-room-facilities
								[babyBeds]="0"
								[facilities]="facilities"
								[maxNumberOfGuests]="0"
								[roomIndex]="0"
								[roomSurface]="roomSurface"
								[showMoreInformationAccordion]="false"
							/>
						}
					</div>
					<div>
						@if (content) {
							<markdown
								[data]="content"
								class="list-checkmarks markdown"
								data-test-id="persuade-card-description"
							/>
						} @else {
							<div data-test-id="persuade-card-content">
								<ng-content [select]="content" />
							</div>
						}
						@if (readMoreUrl) {
							<a
								[routerLink]="readMoreUrl"
								class="text-link"
								data-test-id="persuade-card-link"
							>
								{{ 'global.show-more' | translate }}
							</a>
						}
						@if (tags?.length > 0) {
							<div
								class="mt-6 inline-flex w-full flex-wrap gap-1.5"
							>
								@for (tag of tags; track tag) {
									<vp-tag
										[color]="'green'"
										[isEmphasized]="false"
									>
										{{ tag }}
									</vp-tag>
								}
							</div>
						}
					</div>
				</div>
				<div
					[ngClass]="{
						'md:flex-col': !useVerticalCard,
						'flex-row-reverse': reversedCtaColumn,
					}"
					class="flex min-w-max flex-row justify-between gap-4"
				>
					@if (price) {
						<vp-pricing
							*ngrxLet="isTablet$ as isTablet"
							[alignment]="
								(isMobile || isTablet || useVerticalCard) &&
								!reversedCtaColumn
									? 'self-start'
									: 'self-end'
							"
							[conditions]="pricingCondition | translate"
							[currency]="currency"
							[indication]="true"
							[ngClass]="{
								'md:ml-auto': !useVerticalCard,
							}"
							[price]="price"
							[size]="isMobile || isTablet ? 'base' : 'lg'"
							class="ml-0"
						/>
					}
					<div class="self-end sm:block">
						@if (ctaUrl) {
							<a
								[href]="ctaUrl"
								[ngClass]="ctaType + ' ' + ctaVariant"
								class="btn btn-sm md:btn"
								data-test-id="persuade-card-cta"
								type="button"
							>
								{{ ctaText | translate }}
							</a>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
