import flattenColorPalette from 'tailwindcss/lib/util/flattenColorPalette';
import plugin from 'tailwindcss/plugin';
/**
 * This plugin renders different divider options to use in your project
 * The base exists of just the `divider` class.
 * Available are options to change the width, color and style.
 * For example `divider-cta-200 divider-4 divider-dashed` will give it a different color, size and style
 */

const divider = plugin(
	function ({ addComponents, matchComponents, theme, e }) {
		const styles = theme('dividerStyle');
		const widths = theme('dividerWidth');

		addComponents({
			[`.${e('divider')}`]: {
				borderTopWidth: '1px',
				borderStyle: 'solid',
				borderTopColor: `${theme('colors.ui.100')}`,
			},
		});

		matchComponents(
			{
				divider: (value) => ({
					borderTopColor: value,
				}),
			},
			{
				values: flattenColorPalette({
					...theme('colors'),
				}),
				type: 'color',
			},
		);

		matchComponents(
			{
				divider: (value) => ({
					borderTopWidth: value,
				}),
			},
			{
				values: widths,
			},
		);

		matchComponents(
			{
				divider: (value) => ({
					borderStyle: value,
				}),
			},
			{
				values: styles,
			},
		);
	},
	{
		theme: {
			dividerStyle: {
				solid: 'solid',
				dashed: 'dashed',
				dotted: 'dotted',
				double: 'double',
				none: 'none',
			},
			dividerWidth: {
				0: '0px',
				1: '1px',
				2: '2px',
				4: '4px',
				8: '8px',
			},
		},
	},
);

export default divider;
