import { isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import resolveConfig from 'tailwindcss/resolveConfig';

import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';
import tailwindConfig from '@valk-nx/tailwind.config';

import {
	ImageInterface,
	ImageSourceInterface,
	SizeValues,
} from './image.interface';

const fullConfig = resolveConfig && resolveConfig(tailwindConfig);
const screenSizes = fullConfig && fullConfig.theme['screens'];

@Component({
	standalone: true,
	selector: 'vp-image',
	templateUrl: './image.component.html',
	imports: [AngularSvgIconModule, SharedModule],
})
export class ImageComponent implements OnChanges, OnInit {
	@Input({ required: true }) image: ImageInterface = {
		src: '',
		width: 0,
		height: 0,
	};
	@Input() sources: ImageSourceInterface[] = [];
	@Input() className = '';

	imageWidth = 0;
	isCustomImage = true;

	screenSizes = screenSizes;
	resolvedSources: (ImageSourceInterface & { media: string })[] = [];

	constructor(
		public readonly cd: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private platformId: string,
	) {}

	ngOnInit() {
		this.setImageWidth();
	}

	ngOnChanges(): void {
		this.resolvedSources = [];
		this.sources.forEach((source) => {
			let media = '';
			if (source.min) {
				media = `(min-width: ${this.screenSizes[source.min]})`;
			}
			if (source.min && source.max) {
				media = media + ' and ';
			}
			if (source.max) {
				media = media + `(max-width: ${this.screenSizes[source.max]})`;
			}

			this.resolvedSources.push({
				...source,
				media,
			});
		});
	}

	@HostListener('window:resize')
	@Debounce()
	setImageWidth() {
		if (isPlatformBrowser(this.platformId)) {
			const windowWidth = window.innerWidth;

			const imageSource = this.sources.find(
				(source) =>
					(source.max !== null &&
						windowWidth <= SizeValues[source.max]) ||
					(source.max === null &&
						source.min !== null &&
						windowWidth >= SizeValues[source.min]),
			);

			this.imageWidth = imageSource?.width ? imageSource.width : 0;

			this.cd.detectChanges();
		}
	}

	setImageFallback() {
		this.isCustomImage = false;
		this.image = {
			...this.image,
			src: '/assets/images/application-icons/icon-transparent-96x96.png',
		};
	}
}
