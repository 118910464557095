import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { ColumnComponentTypes } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-columns',
	templateUrl: './columns.html',
	standalone: true,
	imports: [StoryblokDirective, CommonModule, DynamicModule],
})
export class ColumnsComponent extends StoryblokRootDirective {
	@Input() title!: ISbStoryData[];
	@Input() colOne!: ISbStoryData;
	@Input() colTwo!: ISbStoryData;
	@Input() colThree!: ISbStoryData;
	@Input() fullWidth = false;
	@Input() growColumns = false;
	@Input() reverse = false;
	@Input() declare component: ColumnComponentTypes;

	components!: typeof Components;

	constructor() {
		super();
		// eslint-disable-next-line @nx/enforce-module-boundaries
		import('@valk-nx/storyblok-core/src/lib/components')
			.then((cp) => {
				this.components = cp.Components;
			})
			.catch(
				/* istanbul ignore next */ (error) => {
					console.error(error);
				},
			);
	}
}
