import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';
import { Observable } from 'rxjs';

import { CarouselModalComponent } from '@valk-nx/components/ui-carousel-modal/src/lib/carousel-modal';
import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { RoomFacilitiesComponent } from '@valk-nx/components/ui-room-facilities/src/lib/room-facilities.component';
import { TagComponent } from '@valk-nx/components/ui-tag/src/lib/tag';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { RoomCardFacilitiesInterface } from '@valk-nx/rooms-store/rooms.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AngularSvgIconModule,
		CarouselModalComponent,
		CommonModule,
		ImageComponent,
		LetDirective,
		MarkdownModule,
		RoomFacilitiesComponent,
		RouterModule,
		PricingComponent,
		TagComponent,
		TranslateModule,
	],
	selector: `vp-persuade-card`,
	standalone: true,
	templateUrl: './persuade-card.component.html',
})
export class PersuadeCardComponent {
	@Input() images: PhotoInterface[] | undefined;
	@Input() overline: string | undefined;
	@Input({ required: true }) title: string;
	@Input() facilities: RoomCardFacilitiesInterface | undefined;
	@Input() roomSurface: number | undefined;
	@Input() content: string | undefined;
	@Input() price: number | undefined;
	@Input() pricingCondition = 'global.price-per-night';
	@Input() tags: string[] | undefined;
	@Input() currency = 'EUR';
	@Input() ctaUrl: string | undefined;
	@Input() ctaText = 'global.book-directly';
	@Input() ctaType: 'btn-primary' | 'btn-secondary' = 'btn-primary';
	@Input() ctaVariant: '' | 'btn-outline' | 'btn-text' = '';
	@Input() readMoreUrl: string | undefined;
	@Input() reversedCtaColumn = false;
	@Input() useVerticalCard = false;

	isMobile$: Observable<boolean>;
	isTablet$: Observable<boolean>;

	constructor(private readonly viewport: ViewPortService) {
		this.isMobile$ = this.viewport.isMobile$;
		this.isTablet$ = this.viewport.isSmallTablet$;
	}
}
