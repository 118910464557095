<section
	[editableInStoryblok]="_editable"
	[ngClass]="{
		'!bg-accent-10': sectionColor === 'accent',
	}"
	class="bg-white px-3 py-8 md:px-8 md:py-12 lg:py-16 2xl:px-10"
>
	<div
		class="mx-auto flex max-w-screen-2xl flex-col gap-y-4 md:gap-y-6 2xl:gap-y-8"
	>
		@for (blok of columns; track blok) {
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
					class="hidden"
				/>
			}
		}
	</div>
</section>
