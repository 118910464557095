import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';

import { StorytellingComponent } from '@valk-nx/components/ui-storytelling/src/lib/storytelling';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	BackgroundColors,
	BynderImage,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-storytelling',
	templateUrl: './storytelling.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		StorytellingComponent,
		StoryblokDirective,
	],
})
export class StoryblokStoryTellingComponent
	extends StoryblokRootDirective
	implements OnInit
{
	components!: typeof Components;

	@Input({ required: true }) title!: string;
	@Input() overline = '';
	@Input({ required: true }) content!: ISbStoryData[];
	@Input() alignment: 'left' | 'right' = 'left';
	@Input() headingType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h3';
	@Input() backgroundColor: BackgroundColors = 'white';
	@Input() showBackground: boolean;
	@Input({ required: true }) bynder!: BynderImage[];
	@Input() imageAlt = '';
	@Input() titleHeading!: string;

	vpImage: PhotoInterface = { src: '', alt: '' };

	useBackground = false;

	constructor() {
		super();
		// eslint-disable-next-line @nx/enforce-module-boundaries
		import('@valk-nx/storyblok-core/src/lib/components')
			.then((cp) => {
				this.components = cp.Components;
			})
			.catch(
				/* istanbul ignore next */ (error) => {
					console.error(error);
				},
			);
	}

	ngOnInit(): void {
		this.setImage();
		this.useBackground = this.backgroundColor === 'accent';
	}

	setImage(): void {
		if (this.bynder?.length > 0) {
			this.vpImage = StoryblokHelper.mapBynderToPhotoInterface(
				this.bynder[0],
				this.imageAlt,
			);
		} else {
			this.vpImage = {
				src: '',
				alt: this.imageAlt,
			};
		}
	}
}
