import { Component, Input } from '@angular/core';

import { FooterComponent as UIFooter } from '@valk-nx/components/ui-footer/src/lib/footer';
import { FooterMenuItems } from '@valk-nx/components/ui-footer/src/lib/footer.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import { MenuItemsModule } from '@valk-nx/storyblok-pipes/src/lib/pipes/menu-items/menu-items.module';
import {
	Link,
	MenuItem,
	SbFooterMenu,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-footer',
	templateUrl: './footer.html',
	standalone: true,
	imports: [UIFooter, StoryblokDirective, MenuItemsModule],
})
export class FooterComponent extends StoryblokRootDirective {
	@Input() title!: string;
	@Input() usps!: MenuItem[];
	@Input() bottomMenu!: MenuItem[];

	// Socials
	@Input() facebook!: Link;
	@Input() instagram!: Link;
	@Input() linkedin!: Link;
	@Input() pinterest!: Link;
	@Input() tiktok!: Link;
	@Input() youtube!: Link;

	@Input() set menu(menu: SbFooterMenu[]) {
		this.footerMenu = this.constructFooterMenu(menu);
	}

	@Input() footerMenu!: FooterMenuItems[];

	get socials() {
		return {
			facebook: StoryblokHelper.getUrlFromStoryblokLink(this.facebook),
			instagram: StoryblokHelper.getUrlFromStoryblokLink(this.instagram),
			tiktok: StoryblokHelper.getUrlFromStoryblokLink(this.tiktok),
			youtube: StoryblokHelper.getUrlFromStoryblokLink(this.youtube),
			linkedin: StoryblokHelper.getUrlFromStoryblokLink(this.linkedin),
			pinterest: StoryblokHelper.getUrlFromStoryblokLink(this.pinterest),
		};
	}

	constructFooterMenu(menu: SbFooterMenu[]): FooterMenuItems[] {
		return menu?.map((menuItem) => {
			return {
				title: menuItem.title,
				items: menuItem.items.map((item) => {
					return {
						title: item.title,
						url: item.link.cached_url,
						target: item.link.target || '_self',
					};
				}),
			};
		});
	}
}
