import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AlertModule } from '@valk-nx/components/ui-alert/src/lib/alert.module';
import { RecaptchaNotificationComponent } from '@valk-nx/components/ui-recaptcha-notification/src/lib/recaptcha-notification';
import { Language } from '@valk-nx/core/lib/core';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { GiftcardService } from '@valk-nx/storyblok-services/src/lib/services/giftcard.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';
import { Link } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

import { form as formFields } from './balance-giftcard-form';

@Component({
	selector: 'sb-balance-giftcard',
	templateUrl: './balance-giftcard.html',
	standalone: true,
	imports: [
		AlertModule,
		CommonModule,
		RecaptchaNotificationComponent,
		TranslateModule,
		SharedFormlyModule,
		StoryblokDirective,
	],
	providers: [TagManagerFacade],
})
export class BalanceGiftcardComponent extends StoryblokRootDirective {
	@Input() currency = 'EUR';
	@Input() activateGiftcardPage!: Link;

	@ViewChild('balanceForm') balanceForm!: NgForm;

	get showSummaryBalance() {
		return (
			this.giftcardStatus === 'normal' &&
			this.giftcardSubmitted &&
			this.form.value.cardNumber
		);
	}

	get showSummaryInactive() {
		return (
			this.giftcardStatus === 'inactive' &&
			this.giftcardSubmitted &&
			this.form.value.cardNumber &&
			this.form.value.pinCode
		);
	}

	get showSummaryWarning() {
		return (
			this.giftcardStatus !== 'normal' &&
			this.giftcardStatus !== 'inactive' &&
			this.giftcardSubmitted &&
			!this.giftcardBalance &&
			this.form.value.cardNumber &&
			this.form.value.pinCode
		);
	}

	formFields = formFields;
	form = new FormGroup<{
		cardNumber: FormControl;
		pinCode: FormControl;
	}>({
		cardNumber: new FormControl(''),
		pinCode: new FormControl(''),
	});

	giftcardSubmitting = false;
	giftcardSubmitted = false;

	giftcardBalance = 0;
	giftcardStatus = '';
	language: Language;

	constructor(
		private readonly giftcardService: GiftcardService,
		private readonly router: Router,
		private readonly translate: TranslateService,
		private readonly tagManagerFacade: TagManagerFacade,
	) {
		super();
		this.language = (this.translate.currentLang ||
			this.translate.defaultLang) as Language;
	}

	onSubmit(balanceRequest: {
		cardNumber: string;
		pinCode: string;
	}): Promise<{ balance: number; status: string } | null> {
		this.giftcardSubmitting = true;
		this.tagManagerFacade.formSubmit('giftcard_saldo_check');
		return this.giftcardService
			.checkBalance(balanceRequest.cardNumber, balanceRequest.pinCode)
			.then((res) => {
				this.giftcardSubmitted = true;
				this.giftcardSubmitting = false;
				this.giftcardBalance = res.balance;
				this.giftcardStatus = res.status;
				return {
					balance: res.balance,
					status: res.status,
				};
			})
			.catch((error) => {
				this.giftcardSubmitted =
					error.status < 500 && error.status !== 0;
				this.giftcardSubmitting = false;
				return null;
			});
	}

	goToActivateGiftCardForm(): void {
		this.router.navigate([this.activateGiftcardPage.cached_url], {
			queryParams: { cardnumber: this.form.controls['cardNumber'].value },
		});
	}

	resetGiftcardForm(): void {
		this.giftcardSubmitted = false;
		this.giftcardBalance = 0;
		this.giftcardStatus = '';

		this.form.reset();
		this.balanceForm.resetForm();
	}

	changeGiftCardForm(): void {
		this.giftcardSubmitted = false;
	}
}
