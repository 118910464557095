import { NgClass } from '@angular/common';
import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	OnInit,
} from '@angular/core';

import { ISbRichtext, renderRichText } from '@storyblok/js';

import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-richtext',
	templateUrl: './rich-text.html',
	standalone: true,
	imports: [ReadMoreComponent, NgClass, StoryblokDirective],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RichTextComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) content!: ISbRichtext | string;
	@Input() listStyle: 'list-checkmarks' | 'list-bullets' = 'list-bullets';
	@Input() isCentered = false;
	@Input() isReadMore = false;

	renderedRichText = '';

	constructor() {
		super();
	}

	/* istanbul ignore next */
	ngOnInit() {
		// NOTE: Only use renderRichText when storyblok API is enabled
		if (typeof this.content !== 'string') {
			this.renderedRichText = renderRichText(this.content);
		} else {
			this.renderedRichText = this.content;
		}
	}
}
