import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject } from '@angular/core';

@Directive({
	selector: 'a[valkCoreLink]',
})
export class LinkDirective {
	constructor(
		private el: ElementRef,
		@Inject(DOCUMENT) private readonly document: Document,
	) {
		this.el.nativeElement.rel = 'noopener noreferrer';
	}

	@HostListener('click', ['$event'])
	clicked(event: Event) {
		let url = this.el.nativeElement.href;
		const target = this.el.nativeElement.target;

		if (!url) {
			return;
		}

		if (
			url.startsWith(this.document.location.origin) &&
			target !== '_blank'
		) {
			url = url.replace(this.document.location.origin, '');
		}

		if (url.startsWith('https://') || url.startsWith('http://')) {
			this.document.defaultView?.open(url, target || '_blank');
		}

		event.preventDefault();
	}
}
