<div
	[editableInStoryblok]="_editable"
	class="card card-xs relative flex h-full flex-col"
>
	@if (flatImages?.length > 0) {
		<vp-image-grid [images]="flatImages || []" size="base" />
	}
	<div class="flex h-full flex-col gap-4 p-3">
		@for (blok of content; track blok) {
			@if (components) {
				@if ($last && stickyBottom) {
					<div class="mt-auto">
						<ndc-dynamic
							[ndcDynamicComponent]="
								components[blok['component']]
							"
							[ndcDynamicInputs]="blok"
							class="hidden"
						/>
					</div>
				} @else {
					<ndc-dynamic
						[ndcDynamicComponent]="components[blok['component']]"
						[ndcDynamicInputs]="blok"
						class="hidden"
					/>
				}
			}
		}
	</div>
</div>
