import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';
import { OccupancyRestrictionsInterface } from '@valk-nx/hotel-store/hotels.interface';

export const CONTENT_SERVICE_URL = new InjectionToken<string>(
	'content-service-url',
);
export const CONTENT_API_COOKIES = new InjectionToken<string[]>(
	'content-api-cookies',
);
export const HOTEL_SLUG = new InjectionToken<string[]>('hotel-slug');

export interface RoomData {
	accommodationType: 'apartments' | 'bungalows' | 'rooms';
	category: string;
	checkInTime: string;
	checkOutTime: string;
	description: string;
	facilities: {
		bathroom: string[];
		misc: string[];
		sleepingComfort: string[];
	};
	GUID: string;
	hotel: {
		GUID: string;
		facilities: string[];
		goodToKnows: string[];
	};
	images: { alt: string; src: string }[];
	isBookable: boolean;
	meta: {
		description: string;
		title: string;
	};
	occupancyRestrictions: OccupancyRestrictionsInterface;
	price: { amount: number; currency: string };
	slug: string;
	summary: string;
	surfaceArea: number;
	title: string;
}

export interface PackageData {
	GUID: string;
	images: { alt: string; src: string }[];
	name: string;
	slug: string;
	description: string;
	price: { amount: number; currency: string };
	bulletContent: string;
	occupancyRestrictions: OccupancyRestrictionsInterface;
	packages: {
		GUID: string;
		name: string;
		nights: number;
		price: { amount: number; currency: string };
		shijiCode: string;
		IDPMSCode: string;
		bulletContent: string;
		termsAndConditions: string;
	}[];
}

@Injectable({ providedIn: 'root' })
export class ContentService {
	constructor(
		private readonly http: HttpClient,
		@Optional()
		@Inject(CONTENT_SERVICE_URL)
		private readonly serviceUrl: string,
		@Inject(CONTENT_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
		@Inject(HOTEL_SLUG)
		private readonly hotelSlug: string,
	) {}

	getRooms(language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<RoomData[]>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/rooms?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getRoom(roomSlug: string, language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<RoomData>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/rooms/${roomSlug}?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getPackage(packageSlug: string, language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<PackageData>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/package-batches/${packageSlug}?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getPackages(language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<PackageData[]>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/package-batches?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}
}
