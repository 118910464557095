import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { Observable, take } from 'rxjs';

import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { negativeHeader$ } from '@valk-nx/core/lib/core';
import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	ContentService,
	PackageData,
} from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	selector: 'sb-package-detail',
	templateUrl: './package-detail.component.html',
	standalone: true,
	imports: [
		CarouselComponent,
		CommonModule,
		MarkdownModule,
		PersuadeCardComponent,
		ReadMoreComponent,
		StoryblokDirective,
		TranslateModule,
	],
})
export class PackageDetailComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) canonical!: string;

	slug!: TranslatedSlug;
	package: PackageData;

	translatedSlugs$: Observable<TranslatedSlug[]>;

	constructor(
		private readonly contentService: ContentService,
		private readonly cd: ChangeDetectorRef,
		private readonly sbService: StoryblokService,
	) {
		super();
		this.translatedSlugs$ =
			this.sbService.translatedSlugs$.pipe(takeUntilDestroyed());
	}

	ngOnInit() {
		negativeHeader$.next(false);
		this.translatedSlugs$.subscribe((translatedSlug) => {
			this.slug =
				translatedSlug.find((x) => x.selected) || translatedSlug[0];

			this.contentService
				.getPackage(
					this.slug.path.split('/').pop(),
					this.slug.lang.toLowerCase(),
				)
				.pipe(take(1))
				.subscribe(
					(response: HttpResponse<HALResponse<PackageData>>) => {
						this.package = response.body.data;

						this.cd.markForCheck();
					},
				);
		});
	}
}
