import {
	CommonModule,
	isPlatformBrowser,
	isPlatformServer,
} from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	ElementRef,
	Inject,
	Input,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { Sizes } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';
import { ImageQueryModule } from '@valk-nx/core/lib/pipes/image-query/image-query.module';

import { CarouselItem } from './carousel.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	selector: 'vp-carousel',
	styleUrls: ['./carousel.scss'],
	templateUrl: './carousel.html',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		AngularSvgIconModule,
		CommonModule,
		ImageQueryModule,
		LinkComponent,
		ImageComponent,
		MarkdownModule,
		RouterModule,
	],
})
export class CarouselComponent implements AfterViewInit {
	@Input() className = '';
	@Input() config: Partial<SwiperOptions> = {};
	@Input({ required: true }) items: CarouselItem[] = [];

	@ViewChild('swiper') swiperRef: ElementRef | undefined;

	swiper: SwiperContainer | null = null;
	swiperConfig: SwiperOptions = {
		loop: typeof window !== 'undefined' || this.items.length > 1,
		preventClicks: false,
		spaceBetween: 12,
		speed: 500,
	};

	imagesLoaded: boolean[] = [];
	isSSRMode = false;
	isCustomImages: boolean[] = [];

	sizes = Sizes;

	constructor(@Inject(PLATFORM_ID) readonly platformId: string) {
		register();
	}

	ngAfterViewInit() {
		this.imagesLoaded = new Array(this.items.length).fill(false);
		this.isCustomImages = new Array(this.items.length).fill(true);
		this.isSSRMode = isPlatformServer(this.platformId);

		this.swiperConfig = {
			...this.swiperConfig,
			...this.config,
			keyboard: { enabled: true, onlyInViewport: true },
			navigation: false,
		};

		this.swiper = this.swiperRef?.nativeElement;

		if (this.swiper && isPlatformBrowser(this.platformId)) {
			Object.assign(this.swiper, this.swiperConfig);
			this.swiper.initialize();
		}
	}

	onImageLoad(index: number) {
		this.imagesLoaded[index] = true;
	}

	slideNext() {
		this.swiper?.swiper.slideNext(this.swiperConfig.speed);
	}

	slidePrev() {
		this.swiper?.swiper.slidePrev(this.swiperConfig.speed);
	}

	setImageFallback(index: number) {
		this.isCustomImages[index] = false;

		this.items[index] = {
			...this.items[index],
			image: {
				...this.items[index].image,
				src: '/assets/images/application-icons/icon-transparent-96x96.png',
			},
		};
	}
}
