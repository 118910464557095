<div [editableInStoryblok]="_editable" class="flex flex-col">
	@if (carousel) {
		<sb-carousel [config]="carousel.config" [items]="carousel.items" />
	}
	@if (hotel$ | async; as hotel) {
		<div class="z-50 -mt-14 px-3 md:px-8 2xl:px-10">
			<div class="mx-auto w-full max-w-screen-2xl">
				<div class="mx-auto flex w-full flex-col lg:w-10/12 2xl:w-8/12">
					<vp-availability-widget
						[bookingtoolUrl]="this.bookingtool + this.hotelSlug"
						[hotelGUID]="hotel?.GUID || hotelGUID"
						[occupancyRestrictions]="hotel?.occupancyRestrictions"
					/>
				</div>
			</div>
		</div>
	}
</div>
