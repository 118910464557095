<div
	class="flex flex-col items-center gap-4 md:gap-6 2xl:gap-8"
	data-test-id="hotel-by-deal-list"
>
	@if (highlightedHotel) {
		<div class="w-full">
			<h3
				class="running-head-lg mb-0 text-accent-200"
				data-test-id="highlighted-hotel-title"
			>
				{{
					'compositions.hotel-by-deal-card-list.highlighted-hotel'
						| translate
				}}
			</h3>
		</div>
		<div class="w-full 2xl:w-8/12" data-test-id="highlighted-hotel">
			<ng-container
				[ngTemplateOutlet]="hotelByDealCard"
				[ngTemplateOutletContext]="{
					hotel: highlightedHotel,
					isHighlightedHotel: true,
				}"
			/>
		</div>
	}
	<div class="w-full">
		<h3 class="running-head-lg mb-0 text-accent-200">
			{{
				'compositions.hotel-by-deal-card-list.available-hotels'
					| translate
			}}
		</h3>
	</div>
	<div class="w-full 2xl:w-6/12">
		<div class="flex flex-col gap-4 md:flex-row md:gap-6">
			<div class="w-full justify-start md:w-1/2">
				<vp-label
					[labelText]="'compositions.header.search' | translate"
					[position]="labelPosition"
					inputName="hotelListSearch"
				>
					<vp-input
						[placeholder]="
							'compositions.hotel-by-deal-card-list.search-by-hotelname'
								| translate
						"
						(emittedValue)="setSearchText($event)"
						icon="assets/icons/icon-looking-glass.svg"
						iconPosition="right"
						inputId="hotelListSearch"
						name="hotelListSearch"
						type="text"
					/>
				</vp-label>
			</div>
			<div class="w-full justify-end md:w-1/2">
				<vp-label
					[labelText]="
						'compositions.hotel-by-deal-card-list.sort' | translate
					"
					[position]="labelPosition"
					inputName="hotelListSort"
				>
					<vp-dropdown
						[centered]="true"
						[placeholder]="
							'compositions.hotel-by-deal-card-list.sort-by'
								| translate
						"
						[selectData]="sortOptions"
						[value]="
							'compositions.hotel-by-deal-card-list.sort-by'
								| translate
						"
						(selectedValue)="sortHotels($event)"
						data-test-id="sort-options-dropdown"
						inputId="hotelListSort"
						variant="integrated"
					/>
				</vp-label>
			</div>
		</div>
	</div>
	@if (orderedList.length === 0) {
		<div class="w-full 2xl:w-6/12" data-test-id="no-results-warning">
			<vp-alert
				[closeable]="false"
				[content]="
					'compositions.hotel-by-deal-card-list.no-results-found'
						| translate
				"
				[type]="alertType"
			/>
		</div>
	}
	@if (orderedList.length > 0) {
		<div class="flex w-full flex-col gap-3 2xl:w-8/12">
			@for (hotel of orderedList; track hotel) {
				<div>
					<ng-container
						[ngTemplateOutlet]="hotelByDealCard"
						[ngTemplateOutletContext]="{
							hotel: hotel,
						}"
					/>
				</div>
			}
		</div>
	}
</div>

<ng-template
	#hotelByDealCard
	let-hotel="hotel"
	let-isHighlightedHotel="isHighlightedHotel"
>
	<vp-hotel-by-deal-card
		[ctaUrl]="hotel.ctaUrl"
		[currency]="hotel.currency"
		[hotelName]="hotel.hotelName"
		[image]="hotel.image"
		[isHighlightedHotel]="isHighlightedHotel"
		[numberOfRatings]="hotel.numberOfRatings"
		[numberOfStars]="hotel.numberOfStars"
		[price]="hotel.price"
		[rating]="hotel.rating"
		[ribbonColor]="hotel.ribbonColor"
		[ribbonText]="hotel.ribbonText"
		[tags]="hotel.tags"
		[usps]="hotel.usps"
		[validFrom]="hotel.validFrom"
		[validUntil]="hotel.validUntil"
	/>
</ng-template>
