import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertType } from './contracts/alert.types';

@Component({
	animations: [
		trigger('alertAnimationTrigger', [
			state(
				'visible',
				style({
					opacity: 1,
				}),
			),
			state(
				'hidden',
				style({
					opacity: 0,
				}),
			),
			transition('* <=> *', animate('50ms ease-in-out')),
		]),
	],
	selector: 'vp-alert',
	templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
	@Input() title = '';
	@Input() content = '';
	@Input() closeable = false;
	@Input() emphasized = false;
	@Input() isToastr = false;
	@Input() hasElevation = false;
	@Input() type: AlertType = 'dialog--info';
	@Input() size: 'base' | 'small' = 'base';
	@Input() className = '';

	@Output() closeEmitter = new EventEmitter();

	animationState = 'visible';
	hidden = false;

	ngOnInit(): void {
		if (this.isToastr) {
			this.animationState = 'visible';
		}
	}

	remove() {
		if (!this.isToastr) {
			this.animationState = 'hidden';
		} else {
			this.closeEmitter.emit();
		}
	}

	onAlertVisibilityChange() {
		if (this.animationState === 'hidden' && !this.isToastr) {
			this.hidden = true;
		}
	}
}
