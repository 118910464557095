import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

import { catchError, map, Observable, of } from 'rxjs';

import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';

import { ContentHotelInterface, HotelInterface } from './hotels.interface';

//Env variable
export const HOTEL_SERVICE_URL = new InjectionToken<string[]>(
	'hotel_service-url',
);

export const HOTEL_API_COOKIES = new InjectionToken<string[]>(
	'hotel_api-cookies',
);

@Injectable({ providedIn: 'root' })
export class HotelsService {
	constructor(
		private readonly http: HttpClient,
		@Inject(HOTEL_SERVICE_URL) private readonly hotelUrl: string,
		@Inject(HOTEL_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
	) {}

	getHotels(language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});
		return this.http.get<HALResponse<HotelInterface[]>>(
			`${this.hotelUrl}hotels?language=${language}`,
			{ ...this.apiCookies, headers, observe: 'response' },
		);
	}

	getHotelBySlug(language: string, slug: string, filterExtra?: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		const filterExtraQuery = filterExtra ? `&extra=${filterExtra}` : '';

		return this.http.get<HALResponse<HotelInterface[]>>(
			`${this.hotelUrl}hotels?slug=${slug}&language=${language}${filterExtraQuery}`,
			{ ...this.apiCookies, headers, observe: 'response' },
		);
	}

	getHotelBySlugData(language: string, slug: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});
		return this.http
			.get<
				HALResponse<ContentHotelInterface>
			>(`${this.hotelUrl}hotels/${slug}?language=${language}`, { ...this.apiCookies, headers, observe: 'response' })
			.pipe(
				map((response) => response.body.data),
				catchError(() => {
					return of({} as ContentHotelInterface);
				}),
			);
	}

	// This data is hardcoded in the assets, based on an excel sheet in this ticket.
	// Script in the comment
	// https://aanzee.atlassian.net/browse/VPL-6846
	getRestaurantPhoto(
		hotelCode: string,
	): Observable<{ photo: string } | undefined> {
		const headers = new HttpHeaders();
		return this.http
			.get<{ [key: string]: { photo: string } }>(
				`/assets/i18n/restaurant-details.json`,
				{
					headers,
					observe: 'response',
				},
			)
			.pipe(map(({ body }) => body?.[hotelCode]));
	}
}
