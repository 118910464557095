import plugin from 'tailwindcss/plugin';

const tagPlugin = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const white = theme('colors.white');
		addComponents({
			[`.${e('tag')}`]: {
				borderRadius: '100px',
				color: white,
				display: 'inline-block',
				fontSize: theme('fontSize.xs'),
				letterSpacing: theme('letterSpacing.wider'),
				lineHeight: theme('lineHeight.3'),
				padding: `${theme('spacing.1')} ${theme('spacing')['1.5']}`,

				[`@media (min-width: ${theme('screens.md')})`]: {
					fontSize: theme('fontSize.sm'),
					lineHeight: theme('lineHeight.4'),
					padding: `${theme('spacing')['1.5']} ${theme('spacing.3')}`,
				},
			},
			[`.${e('tag-emphasized')}`]: {
				fontWeight: theme('fontWeight.semibold'),
				textTransform: 'uppercase',
			},

			[`.${e('tag-black')}`]: {
				background: `var(--tag-black-color-background, ${theme(
					'colors.black',
				)})`,
				color: `var(--tag-black-color-text,  ${white})`,
			},

			[`.${e('tag-blue')}`]: {
				background: `var(--tag-blue-color-background, ${theme(
					'colors.select.100',
				)})`,
				color: `var(--tag-blue-color-text,  ${white})`,
			},

			[`.${e('tag-dark-blue')}`]: {
				background: `var(--tag-dark-blue-color-background, ${theme(
					'colors.select.200',
				)})`,
				color: `var(--tag-dark-blue-color-text,  ${white})`,
			},

			[`.${e('tag-green')}`]: {
				background: `var(--tag-green-color-background, ${theme(
					'colors.success.300',
				)})`,
				color: `var(--tag-green-color-text,  ${white})`,
			},

			[`.${e('tag-light-green')}`]: {
				background: `var(--tag-light-green-color-background, ${theme(
					'colors.success.100',
				)})`,
				color: `var(--tag-light-green-color-text,  ${white})`,
			},

			[`.${e('tag-light-lime')}`]: {
				background: `var(--tag-light-lime-color-background, ${theme(
					'colors.success.10',
				)})`,
				color: `var(--tag-light-lime-color-text, #000000BF)`,
			},

			[`.${e('tag-orange')}`]: {
				background: `var(--tag-orange-color-background, ${theme(
					'colors.cta.100',
				)})`,
				color: `var(--tag-orange-color-text,  ${white})`,
			},

			[`.${e('tag-magenta')}`]: {
				background: `var(--tag-magenta-color-background, ${theme(
					'colors.error.100',
				)})`,
				color: `var(--tag-magenta-color-text,  ${white})`,
			},

			[`.${e('tag-accent')}`]: {
				background: `var(--tag-accent-color-background, ${theme(
					'colors.ui.50',
				)})`,
				color: `var(--tag-accent-color-text, #000)`,
			},
		});
	};
});

export default tagPlugin;
