const smallMobileMaxWidth = 320;
const mobileMaxWidth = 639;
const smallTabletMaxWidth = 767;
const tabletMaxWidth = 1023;

export function isSmallMobile(currentScreenWidth: number) {
	return currentScreenWidth <= smallMobileMaxWidth;
}

export function isMobile(currentScreenWidth: number) {
	return currentScreenWidth <= mobileMaxWidth;
}

export function isSmallTablet(currentScreenWidth: number) {
	return currentScreenWidth <= smallTabletMaxWidth;
}

export function isTablet(currentScreenWidth: number) {
	return currentScreenWidth <= tabletMaxWidth;
}
export function isDesktop(currentScreenWidth: number) {
	return currentScreenWidth > tabletMaxWidth;
}
