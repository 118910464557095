<details
	[ngClass]="{
		'border-accent-50 open:border-accent-100 hover:border-accent-100':
			variant === 'accent',
		'border-ui-100 open:border-ui-200 hover:border-ui-200':
			variant === 'base',
	}"
	class="group rounded border border-l-4 bg-white"
>
	<summary
		class="flex cursor-pointer items-start px-3 py-2 font-bold md:px-4 md:py-3 [&::-webkit-details-marker]:hidden"
	>
		{{ title }}
		<div class="ml-auto mt-1 size-4 flex-shrink-0 text-black">
			<svg-icon
				class="hidden group-not-open:block"
				src="assets/icons/icon-plus.svg"
			/>
			<svg-icon
				class="hidden group-open:block"
				src="assets/icons/icon-minus.svg"
			/>
		</div>
	</summary>
	<main class="px-3 pb-2 md:px-4 md:pb-3">
		<ng-content />
	</main>
</details>
